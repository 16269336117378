import {AmbientLight, DirectionalLight, Vector3,} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {ThirdSceneServiceDebug} from "service/scene-3/ThirdSceneServiceDebug";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {Constants} from "../../utils/Constants";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class ThirdSceneService extends SceneService {

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_3, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(4.52, 0.96, 8.74);
        this.initialCameraTargetPosition = new Vector3(-0.39, 1.62, 0.71);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init(false);
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_3.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_3.glb`);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.52);
        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xffffff, 0.71);
        directionalLight.position.set(0, 4, 0);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(2048, 2048);
        directionalLight.shadow.camera.top = 3;
        directionalLight.shadow.camera.right = 6;
        directionalLight.shadow.camera.bottom = -8;
        directionalLight.shadow.camera.left = -5;
        directionalLight.shadow.camera.near = 2.25;
        directionalLight.shadow.camera.far = 8;
        directionalLight.shadow.radius = 7;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        this.isDebug && ThirdSceneServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight);
    }

    initCamera = () => {
        super.initCamera(22.5);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && ThirdSceneServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    moveCameraToNextScene = async () => {
        // TODO: change scene to 4!!!!
        return super.moveCameraToNextScene(Scenes.SCENE_5);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_2);
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
