import {AmbientLight, DirectionalLight, PointLight, Vector3,} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {FifthSceneServiceDebug} from "service/scene-5/FifthSceneServiceDebug";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {Constants} from "../../utils/Constants";
import {SkyboxSingleton} from "../SkyboxSingleton";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class FifthSceneService extends SceneService {

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_5, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(-15.84, 2, 11.56);
        this.initialCameraTargetPosition = new Vector3(-28.65, 0.59, -4.68);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init();
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_5.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_5.glb`);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.52);
        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xffffff, 0.2);
        directionalLight.position.set(0, 5.22, 0);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(512, 512);
        directionalLight.shadow.camera.top = 10.5;
        directionalLight.shadow.camera.right = -13.4;
        directionalLight.shadow.camera.bottom = -11.7;
        directionalLight.shadow.camera.left = -27.6;
        directionalLight.shadow.camera.near = 3.9;
        directionalLight.shadow.camera.far = 7;
        directionalLight.shadow.radius = 3;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        const pointLight = new PointLight(0xffffff, 0.1);
        pointLight.castShadow = true;
        pointLight.position.set(-27, 1.77, -2.89)
        pointLight.shadow.radius = 10;
        pointLight.shadow.mapSize.set(1024, 1024);
        pointLight.shadow.bias = -0.00035;
        this.scene.add(pointLight)

        this.isDebug && FifthSceneServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight, pointLight);
    }

    initCamera = () => {
        super.initCamera(24.6);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && FifthSceneServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    initSkybox() {
        this.skybox = SkyboxSingleton.getSkybox(this.loadManager, new Vector3(25, 25, 25));
        this.scene.add(this.skybox);
        this.skybox.position.set(-19.78, -4.28, -2.08);
        this.skybox.rotation.set(0, 1.25, 0);
        this.isDebug && FifthSceneServiceDebug.initSkyboxDebug(this.skybox);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_3);
    }

    moveCameraToNextScene = async () => {
        return super.moveCameraToNextScene(Scenes.SCENE_6);
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
