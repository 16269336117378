import React, {useContext} from "react";
import {observer} from "mobx-react";
import {AnimatePresence} from "framer-motion";
import SecondSceneCanvas from "components/scene-2/SecondSceneCanvas";
import SecondSceneUI from "components/scene-2/SecondSceneUI";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const SecondScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_2 && !dataLoading;

    return (
        <>
            <SecondSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <SecondSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(SecondScene);