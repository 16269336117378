import {SceneElementsCoordinates} from "service/SceneElementsCoordinates";
import {SceneEventConsumer, SceneEventConsumerCallback} from "service/SceneEvents";
import {
    AnimationMixer, Audio, AudioListener, AudioLoader, BasicShadowMap,
    Clock, LoadingManager,
    Mesh, PCFShadowMap,
    PerspectiveCamera,
    Scene, sRGBEncoding,
    Vector3,
    WebGLRenderer
} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {OSUtils} from "utils/OSUtils";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Scenes} from "service/Scenes";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {interpolateCameraControls, interpolateValue} from "../utils/InterpolationUtils";
import {SkyboxSingleton} from "./SkyboxSingleton";
import {Constants} from "../utils/Constants";

const Stats = require('stats-js')
const TIME_TO_SUSPENSE_LOADING: number = 4000;
const SOUND_CHANGE_DURATION = 2;

/**
 * Общий класс для сцены, содержит общие поля и методы для любой сцены.
 */
export abstract class SceneService<E = any> {

    sceneName: Scenes | string;

    /**
     * Признак того, что сцена в режиме разработки. Позволяет отключать дебаг-элементы
     */
    isDebug: boolean = true

    scene: Scene = new Scene();

    canvas: HTMLCanvasElement;

    camera: PerspectiveCamera;

    cameraControls: OrbitControls;

    renderer: WebGLRenderer;

    /**
     * Маппинг ивент-консьюмеров на конкретные события.
     */
    eventsConsumers: Map<E, SceneEventConsumer[]> = new Map<E, SceneEventConsumer[]>();

    sceneElementsCoordinates: SceneElementsCoordinates;

    commonEventsService: CommonEventsService;

    /**
     * Содержит текущий размер вьюпорта.
     */
    viewPortSize: { width: number, height: number };

    sceneModel: GLTF;

    skybox: Mesh;

    animationMixer: AnimationMixer;

    clock: Clock = new Clock();

    requestAnimationFrameId: number;

    renderIsStopped: boolean = true;

    initialCameraPosition: Vector3 = new Vector3(12, 18, 39.24);

    initialCameraTargetPosition: Vector3 = new Vector3(5, -5.76, -10.89);

    stats: any;

    loadManager: LoadingManager;

    cameraStandardZoom: number = 1;

    cameraFirstZoom: number = 1.2;

    cameraSecondZoom: number = 1.4;

    firstMaxAspectRatio: number = 2.3;

    secondMaxAspectRatio: number = 2.7;

    excludeFromReceiveShadows: string[] = [];

    excludeFromCastShadows: string[] = [];

    excludeFromVertexNormalsComputing: string[] = [];

    startLoading: number;

    audioListener: AudioListener;

    sound: Audio;

    audioBuffer: AudioBuffer;

    useSound: boolean;

    userContinueAction: boolean;

    protected constructor(
        commonEventsService: CommonEventsService,
        canvas: HTMLCanvasElement,
        sceneName: Scenes | string,
        useSound: boolean,
        userContinueAction: boolean = true
    ) {
        this.commonEventsService = commonEventsService;
        this.canvas = canvas;
        this.sceneName = sceneName;
        this.useSound = useSound;
        this.userContinueAction = userContinueAction;
    }

    init(needSkybox: boolean = true) {
        /*this.stats = new Stats();
        document.body.appendChild(this.stats.dom);*/

        this.initLoadingManager();
        this.initViewPortSize();
        this.initRenderer();
        this.initCamera();
        this.initSceneElementsCoordinates(this.camera, this.renderer, this.scene);
        this.initCanvasResizingListener();
        this.initCameraControls();
        needSkybox && this.initSkybox();
    }

    loadAudio = (trackPath: string) => {
        const loader = new AudioLoader(this.loadManager);
        loader.load(trackPath, (buffer) => {
            this.audioBuffer = buffer;
        });
    }

    initAudio = () => {
        this.audioListener = new AudioListener();
        this.camera.add(this.audioListener);
        this.sound = new Audio(this.audioListener);
        this.sound.setBuffer(this.audioBuffer);
        this.sound.setLoop(true);
        this.sound.setVolume(0);
        this.audioListener.context.resume();
    }

    initLoadingManager() {
        this.loadManager = new LoadingManager();
        this.loadManager.onStart = () => {
            this.commonEventsService.fireLoadingBegunEvent();
            this.startLoading = Date.now();
        };
        this.loadManager.onLoad = this.suspendedOnAllResourcesLoaded;
    }

    playAmbient = () => {
        this.sound.play();
        if (!this.useSound) return;

        interpolateValue(
            0,
            1,
            SOUND_CHANGE_DURATION,
            value => this.sound.setVolume(value)
        );
    }

    stopAmbient = () => {
        if (!this.useSound) {
            this.sound.stop();
            return;
        }

        interpolateValue(
            1,
            0,
            SOUND_CHANGE_DURATION,
            value => this.sound.setVolume(value),
            this.sound.stop
        );
    }

    turnOnVolume = (duration: number = SOUND_CHANGE_DURATION) => {
        interpolateValue(
            0,
            1,
            duration,
            value => this.sound.setVolume(value)
        );
    }

    turnOffVolume = (duration: number = SOUND_CHANGE_DURATION) => {
        interpolateValue(
            1,
            0,
            duration,
            value => this.sound.setVolume(value)
        );
    }

    suspendedOnAllResourcesLoaded = async (): Promise<void> => {
        if (!this.userContinueAction) {
            this.commonEventsService.fireWaitUserActionEvent();
            return;
        }

        const timeToWait = TIME_TO_SUSPENSE_LOADING - (Date.now() - this.startLoading);

        if (timeToWait > 0) {
            setTimeout(() => this.continue(Constants.LOADER_DELAY), timeToWait);
        } else {
            this.continue(Constants.LOADER_DELAY);
        }
    }

    continue = (delay?: number) => {
        if (delay) {
            this.onAllResourcesLoaded();
            setTimeout(() => this.commonEventsService.fireLoadingCompletedEvent(), delay);
        } else {
            this.onAllResourcesLoaded();
            this.commonEventsService.fireLoadingCompletedEvent();
        }
    }

    onAllResourcesLoaded() {
        this.initSceneModel();
        this.initAudio();
        this.playAmbient();
    }

    initSceneModel() {
        this.sceneModel.scene.traverse(child => {
            child.frustumCulled = false;

            if (child instanceof Mesh) {
                this.processMeshShadowing(child);
            }
        });

        this.animationMixer = new AnimationMixer(this.sceneModel.scene);
        this.scene.add(this.sceneModel.scene);

        this.startRendering();
        this.processSceneElementsCoordinatesRefreshing();
    }

    processMeshShadowing(mesh: Mesh<any, any>, turnOffVertexNormalsComputing: boolean = false) {
        !this.excludeFromReceiveShadows.includes(mesh.name) && (mesh.receiveShadow = true);
        !this.excludeFromCastShadows.includes(mesh.name) && (mesh.castShadow = true);

        if (!turnOffVertexNormalsComputing) {
            !this.excludeFromVertexNormalsComputing.includes(mesh.name) && mesh.geometry?.computeVertexNormals();
        }
    }

    initSceneElementsCoordinates = (camera: PerspectiveCamera, renderer: WebGLRenderer, scene: Scene) => {
        this.sceneElementsCoordinates = new SceneElementsCoordinates(camera, renderer, scene);
    }

    canvasResizingEventListener = () => {
        this.viewPortSize.width = window.innerWidth;
        this.viewPortSize.height = window.innerHeight;
        this.zoomCameraIfNeed();
        this.camera.aspect = this.getAspectRatio();
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.viewPortSize.width, this.viewPortSize.height);
        this.processSceneElementsCoordinatesRefreshing();
        this.commonEventsService.fireCanvasResizedEvent();
    }

    /**
     * Добавляет действия на изменение размера экрана. Выполняет пересчёт координат для объектов-пустышек под HTML
     * элементы на сцене и выкидывает событие об изменении размера канваса.
     */
    initCanvasResizingListener = () => {
        window.addEventListener('resize', this.canvasResizingEventListener);
        window.addEventListener("orientationchange", this.canvasResizingEventListener)
    }

    initViewPortSize = () => {
        this.viewPortSize = {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    initRenderer(alpha: boolean = false) {
        this.renderer = new WebGLRenderer({
            canvas: this.canvas,
            antialias: false,
            alpha: alpha
        });

        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.setSize(this.viewPortSize.width, this.viewPortSize.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.shadowMap.enabled = !OSUtils.isMobile();
        this.renderer.shadowMap.type = BasicShadowMap;
    }

    initCamera(fov: number = 45) {
        this.camera = new PerspectiveCamera(fov, this.getAspectRatio(), 0.1, 500);
        this.zoomCameraIfNeed();
        this.camera.position.set(
            this.initialCameraPosition.x,
            this.initialCameraPosition.y,
            this.initialCameraPosition.z
        );

        this.camera.updateProjectionMatrix();
        this.scene.add(this.camera);
    }

    zoomCameraIfNeed = () => {
        if (this.getAspectRatio() >= this.secondMaxAspectRatio) {
            this.camera.zoom = this.cameraSecondZoom;
        } else if (this.getAspectRatio() >= this.firstMaxAspectRatio) {
            this.camera.zoom = this.cameraFirstZoom;
        } else {
            this.camera.zoom = this.cameraStandardZoom;
        }
    }

    getAspectRatio = (): number => {
        return this.viewPortSize.width / this.viewPortSize.height;
    }

    initCameraControls() {
        this.cameraControls = new OrbitControls(this.camera, this.canvas);
        this.cameraControls.enabled = this.isDebug;

        if (this.isDebug) {
            this.cameraControls.addEventListener('change', () => {
                console.debug(this.camera.position);
                console.debug(this.cameraControls.target);
                console.debug("-----------------------------------")
            });
        }

        this.cameraControls.target.set(
            this.initialCameraTargetPosition.x,
            this.initialCameraTargetPosition.y,
            this.initialCameraTargetPosition.z
        );
    }

    initSkybox() {
        this.skybox = SkyboxSingleton.getSkybox(this.loadManager);
        this.scene.add(this.skybox);
    }

    /**
     * Загружает сцену, предварительно выкинув событие начала загрузки. По завершению - выкидывает событие завершения
     * загрузки.
     */
    loadSceneModel = (path: string, withLoadingManager: boolean = true, callback?: (glb?: GLTF) => void) => {
        const loader = new GLTFLoader(withLoadingManager ? this.loadManager : undefined);
        const dracoLoader = new DRACOLoader();
        this.setDRACODecoderPath(dracoLoader);
        loader.setDRACOLoader(dracoLoader);

        loader.load(
            path,
            (glb) => {
                this.sceneModel = glb;
                callback?.(glb);
            }
        );
    }

    moveCameraToFirstScene() {
        // do nothing...
    }

    moveCameraToNextScene(scene: Scenes, cameraPosition?: Vector3, cameraTargetPosition?: Vector3, duration: number = 1.5) {
        const displacementFactor = 0.5;

        const cameraNewPosition = cameraPosition || new Vector3(
            this.camera.position.x,
            this.camera.position.y + displacementFactor,
            this.camera.position.z
        );

        const cameraTargetNewPosition = cameraTargetPosition || new Vector3(
            this.cameraControls.target.x,
            this.cameraControls.target.y + displacementFactor,
            this.cameraControls.target.z
        );

        this.stopAmbient()
        return this.moveCameraToScene(scene, cameraNewPosition, cameraTargetNewPosition, duration);
    };

    moveCameraToPreviousScene(scene: Scenes, cameraPosition?: Vector3, cameraTargetPosition?: Vector3, duration: number = 1.5) {
        const displacementFactor = 0.5;

        const cameraNewPosition = cameraPosition || new Vector3(
            this.camera.position.x,
            this.camera.position.y - displacementFactor,
            this.camera.position.z
        );

        const cameraTargetNewPosition = cameraTargetPosition || new Vector3(
            this.cameraControls.target.x,
            this.cameraControls.target.y - displacementFactor,
            this.cameraControls.target.z
        );

        this.stopAmbient()
        return this.moveCameraToScene(scene, cameraNewPosition, cameraTargetNewPosition, duration);
    };

    moveCameraToScene(scene: Scenes, cameraNewPosition: Vector3, cameraTargetNewPosition: Vector3, duration: number) {
        this.commonEventsService.fireSceneChangingProcessEvent(scene);
        return this.moveCamera(cameraNewPosition, cameraTargetNewPosition, duration)
            .then(() => this.commonEventsService.fireSceneChangedEvent(scene));
    }

    /**
     * Перемещает камеру, предварительно выкинув событие начала движения камеры. По завершению - выкидывает событие
     * завершения движения камеры.
     */
    moveCamera = (cameraNewPosition: Vector3, targetNewPosition: Vector3, duration: number = 1) => {
        this.commonEventsService.fireCameraMovingBegunEvent();
        return interpolateCameraControls(
            this.camera,
            this.cameraControls,
            cameraNewPosition,
            targetNewPosition,
            this.camera.zoom,
            duration
        ).then(() => {
            this.processSceneElementsCoordinatesRefreshing();
            this.commonEventsService.fireCameraMovingCompletedEvent();
        });
    }

    processSceneElementsCoordinatesRefreshing = async () => {
        this.sceneElementsCoordinates.refreshCoordinates();
        await this.commonEventsService.fireHTMLElementsCoordinatesUpdatedEvent();
    }

    /**
     * Находит список подписчиков на событие и вызывает их.
     */
    fireEvent = (event: E, data?: any) => {
        const consumers = this.eventsConsumers.get(event);
        if (consumers) {
            consumers.forEach(cons => cons.callback(data))
        }
    }

    /**
     * Consumes callback on event. Returns callback for removing consumer from the consumers list.
     * @param sceneEvent
     * @param callBack
     */
    consumeOnEvent = (sceneEvent: E, callBack: SceneEventConsumerCallback) => {
        const newConsumer = new SceneEventConsumer(callBack);
        let consumers = this.eventsConsumers.get(sceneEvent);

        if (!consumers) {
            consumers = [];
            this.eventsConsumers.set(sceneEvent, consumers);
        }

        consumers.push(newConsumer);

        return () => {
            consumers = consumers.filter(consumer => consumer.id !== newConsumer.id);
        }
    }

    toggleRendering(toggle: boolean) {
        if (toggle) {
            this.startRendering();
        } else {
            this.stopRendering();
        }

        this.camera.position.set(
            this.initialCameraPosition.x,
            this.initialCameraPosition.y,
            this.initialCameraPosition.z
        );
        this.cameraControls.target.set(
            this.initialCameraTargetPosition.x,
            this.initialCameraTargetPosition.y,
            this.initialCameraTargetPosition.z
        );
    }

    startRendering = () => {
        if (this.renderIsStopped) {

            if (this.sceneModel) {
                this.scene.add(this.sceneModel.scene)
            }

            this.render();
            this.onStartRendering();
            this.renderIsStopped = false;
            // console.debug(this.sceneName, "RENDER START")
        }
    }

    startAnimations = () => {
        this.sceneModel.animations.forEach(clip => this.animationMixer.clipAction(clip).play());
    }

    onStartRendering(): void {
        this.startAnimations();
    };

    stopRendering = () => {
        window.cancelAnimationFrame(this.requestAnimationFrameId);
        this.onStopRendering();
        this.renderIsStopped = true;
    }

    stopAnimations = () => {
        this.sceneModel.animations.forEach(clip => this.animationMixer.clipAction(clip).stop());
    }

    onStopRendering(): void {
        this.stopAnimations();
    };

    clearContext = (): void => {
        window.removeEventListener("resize", this.canvasResizingEventListener)
        window.removeEventListener("orientationchange", this.canvasResizingEventListener)
        this.stopRendering()
        this.renderer.forceContextLoss();
        this.sceneModel.scene.traverse(obj => {
            if (obj instanceof Mesh) {
                obj.geometry?.dispose();
                obj.material?.dispose();
            }
        })
        this.scene.remove(this.sceneModel.scene)
        this.renderer.renderLists.dispose()
        this.renderer.dispose();
    }

    abstract render(force?: boolean): void;

    protected setDRACODecoderPath = (dracoLoader: DRACOLoader) => {
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.3/');
    }
}