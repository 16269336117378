import React, {useContext} from "react";
import {observer} from "mobx-react";
import LoaderSceneCanvas from "components/loader-scene/LoaderSceneCanvas";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {AnimatePresence} from "framer-motion";
import LoaderSceneUI from "./LoaderSceneUI";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const LoaderScene: React.FC = () => {
    const {dataLoading} = useContext<AppContext>(GlobalAppContext);

    return (
        <>
            <LoaderSceneCanvas visible={dataLoading}/>
            <AnimatePresence>{dataLoading && <LoaderSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(LoaderScene);