import React, {useContext, useEffect, useState} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {Vector3} from "three";
import {AnimatePresence} from "framer-motion";
import HtmlFor3D from "components/HtmlFor3D";
import {CitySceneCameraPosition} from "service/scene-1/CitySceneCameraPosition";
import {CitySceneEvents} from "service/scene-1/CitySceneEvents";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {AnimatedBlockWrapper} from "../AnimatedBlockWrapper";
import {CircleButton} from "../common/circle-button/CircleButton";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {SceneService} from "../../service/SceneService";
import {CitySceneService} from "../../service/scene-1/CitySceneService";
import {openLink} from "../../utils/UIUtils";
import {ConditionShow} from "../ConditionShow";
import "./CitySceneUI.less";

const CitySceneUI: React.FC = () => {
    const context = useContext<AppContext>(GlobalAppContext);
    const {currentSceneService, cameraIsMoving, showCitySceneBanner} = context;
    const [cameraPosition, setCameraPosition] = useState<CitySceneCameraPosition>(CitySceneCameraPosition.TOP);

    useEffect(() => {
        if (currentSceneService) {
            const removeConsumer = currentSceneService.consumeOnEvent(
                CitySceneEvents.CAMERA_POSITION_CHANGED,
                position => setCameraPosition(position)
            );

            return () => removeConsumer();
        }
    }, [currentSceneService]);

    const onDownClick = () => {
        (currentSceneService as CitySceneService).moveCameraToSecondView();
    }

    const onUpClick = () => {
        (currentSceneService as CitySceneService).moveCameraToFirstView();
    }

    const toNextSceneClick = () => {
        (currentSceneService as CitySceneService).moveCameraToNextScene();
    }

    const onLearnMoreClick = () => {
        openLink("https://www.theblock.co/post/130653/crypto-market-maker-byte-trading-raises-7-million-in-seed-funding");
    }

    const onCloseBannerClick = () => {
        context.showCitySceneBanner = false;
    }

    const showDownButton = !cameraIsMoving && cameraPosition === CitySceneCameraPosition.TOP;
    const showTopButton = !cameraIsMoving && cameraPosition === CitySceneCameraPosition.BOTTOM;

    return (
        <SceneUILayoutWrapper className="first-scene-ui">
            <DownButton citySceneService={(currentSceneService as CitySceneService)} onClick={onDownClick}
                        visible={showDownButton}/>
            <SecondSceneButton citySceneService={(currentSceneService as CitySceneService)} onClick={toNextSceneClick}
                               visible={showTopButton}/>
            <MainControlButtons soundButtonVisible={!cameraIsMoving}
                                homeButtonVisible={showTopButton}
                                joinByteButtonVisible={showTopButton}
                                onHomeButtonClick={onUpClick}/>
            <ConditionShow condition={showCitySceneBanner}>
                <Banner onCloseClick={onCloseBannerClick} onLinkClick={onLearnMoreClick}/>
            </ConditionShow>
        </SceneUILayoutWrapper>
    );
}

export default observer(CitySceneUI);

type ButtonForSceneProps = {
    citySceneService: SceneService;
    onClick: () => void;
    visible: boolean;
}

const DownButton: React.FC<ButtonForSceneProps> = (props: ButtonForSceneProps) => {
    const {citySceneService, onClick, visible} = props;

    return (
        <AnimatePresence>
            {visible &&
            <HtmlFor3D sceneService={citySceneService} position={new Vector3(7.2, 1.2, 0)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={onClick}>Discover Byte Street</CircleButton>
                </AnimatedBlockWrapper>
            </HtmlFor3D>}
        </AnimatePresence>
    );
}

const SecondSceneButton: React.FC<ButtonForSceneProps> = (props: ButtonForSceneProps) => {
    const {citySceneService, onClick, visible} = props;

    return (
        <AnimatePresence>
            {visible &&
            <HtmlFor3D sceneService={citySceneService} position={new Vector3(5.5, 3.5, 0)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={onClick}>Enter the building</CircleButton>
                </AnimatedBlockWrapper>
            </HtmlFor3D>}
        </AnimatePresence>
    );
}

type BannerProps = {
    onCloseClick: () => void;
    onLinkClick: () => void;
}

const Banner: React.FC<BannerProps> = (props: BannerProps) => {
    const {onCloseClick, onLinkClick} = props

    return (
        <div className="banner">
            <div className="banner-content">
                <div className="banner-text">
                    Crypto market maker Byte Trading raised $7 million in seed funding 🚀
                </div>
                <button className="banner-button" onClick={onLinkClick}>Learn more</button>
            </div>
            <span className="close-icon" onClick={onCloseClick}/>
        </div>
    );
}
