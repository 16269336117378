import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import ThirdSceneCanvas from "components/scene-3/ThirdSceneCanvas";
import ThirdSceneUI from "components/scene-3/ThirdSceneUI";
import {AnimatePresence} from "framer-motion";


/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const ThirdScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_3 && !dataLoading;

    return (
        <>
            <ThirdSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <ThirdSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(ThirdScene);