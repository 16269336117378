import {Variants} from "framer-motion";

export enum SceneVariantsTypes {
    SHOW = "SHOW",
    HIDE = "HIDE"
}

export const sceneVariants: Variants = {
    [SceneVariantsTypes.SHOW]: {
        pointerEvents: "all",
        opacity: 1,
        transition: {
            duration: 0.2,
            delay: 0.2
        }
    },
    [SceneVariantsTypes.HIDE]: {
        opacity: 0,
        transition: {
            duration: 0.2
        },
        transitionEnd: {
            pointerEvents: "none"
        }
    }
}