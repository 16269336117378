import {
    AmbientLight,
    AnimationClip, BasicShadowMap,
    DirectionalLight,
    Group,
    Mesh, PCFShadowMap,
    PointLight,
    SpotLight, sRGBEncoding,
    TextureLoader,
    Vector3, WebGLRenderer
} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {SeventhServiceDebug} from "./SeventhServiceDebug";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {interpolateVector} from "../../utils/InterpolationUtils";
import {Constants} from "../../utils/Constants";
import {SeventhSceneEvents} from "./SeventhSceneEvents";
import {SeventhSceneCameraPosition} from "./SeventhSceneCameraPosition";
import {PersName, PersScene} from "./PersScene";
import {OSUtils} from "../../utils/OSUtils";

const PERSES_MODELS_PATH = `${Constants.MODELS_BASE_PATH}/perses`;
const PERSES_IMAGES_PATH = `${Constants.IMAGES_BASE_PATH}/perses`;
const MACHINE_BUTTON_CLIP: string = "Machine_buttonAction";

const PERSES_ORDER: PersName[] = [
    PersName.NFT0170,
    PersName.NFT0363,
    PersName.NFT0197,
    PersName.NFT0146,
    // PersName.NFT0236,
    PersName.NFT0214,
    PersName.NFT0111,
    PersName.NFT0320,
    PersName.NFT0428,
    PersName.NFT0009,
    // PersName.NFT0026
]

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class SeventhSceneService extends SceneService {

    secondViewCameraPosition: Vector3 = new Vector3(9.8, 0.63, -1.77);

    secondViewCameraTargetPosition: Vector3 = new Vector3(4.76, 1.044, -1.85);

    persesLoading: boolean[] = [];

    perses: Map<PersName, PersScene> = new Map<PersName, PersScene>();

    stand: GLTF;

    persInitialPosition: Vector3 = new Vector3(7.67, 0.15, -1.32);

    persActivePosition: Vector3 = new Vector3(7.63, 0.67, -1.32);

    activePers: PersName = PersName.NFT0170;

    buttonAnimationClip: AnimationClip;

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_7, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(19.6, 1.06, -2.46);
        this.initialCameraTargetPosition = new Vector3(4.62, 1.3, 2.01);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init(true);
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_7.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_7.glb`);
    }

    loadSceneModel = (path: string) => {
        const loader = new GLTFLoader(this.loadManager);
        const dracoLoader = new DRACOLoader();
        this.setDRACODecoderPath(dracoLoader);
        loader.setDRACOLoader(dracoLoader);
        loader.load(path, (glb) => this.sceneModel = glb);
        this.loadPerses();
    }

    onAllResourcesLoaded = () => {
        this.initPersesModels();
        this.initStandModel();
        this.initAudio();
        this.playAmbient();
        super.initSceneModel();
    }

    initPersesModels = () => {
        this.perses.forEach((persScene) => {
            persScene.gltf.scene.traverse(mesh => {
                mesh.frustumCulled = false;

                if (mesh instanceof Mesh) {
                    this.processMeshShadowing(mesh);
                }
            });

            persScene.gltf.scene.rotation.y = Math.PI / 2;

            if (this.activePers == persScene.name) {
                persScene.gltf.scene.position.set(this.persActivePosition.x, this.persActivePosition.y, this.persActivePosition.z);
            } else {
                persScene.gltf.scene.position.set(this.persInitialPosition.x, this.persInitialPosition.y, this.persInitialPosition.z);
            }

            this.updateScreenMaterial();
            this.scene.add(persScene.gltf.scene);
        });
    }

    initStandModel = () => {
        this.stand.scene.traverse(mesh => {
            mesh.frustumCulled = false;

            if (mesh instanceof Mesh) {
                this.processMeshShadowing(mesh);
            }
        });

        this.stand.scene.rotation.y = Math.PI / 2;
        this.stand.scene.position.set(this.persActivePosition.x, 0.63, this.persActivePosition.z)
        this.scene.add(this.stand.scene);
        SeventhServiceDebug.checkPosition(this.stand.scene, 99)
    }

    startAnimations = () => {
        this.sceneModel.animations.forEach(clip => {
            if (clip.name !== MACHINE_BUTTON_CLIP) {
                this.animationMixer.clipAction(clip).play()
            } else {
                this.buttonAnimationClip = clip;
            }
        });
    }

    moveCameraToMainView = async () => {
        this.moveCamera(this.initialCameraPosition, this.initialCameraTargetPosition, 3)
            .then(() => this.fireEvent(SeventhSceneEvents.CAMERA_POSITION_CHANGED, SeventhSceneCameraPosition.MAIN));
    }

    moveCameraToMachineView = async () => {
        this.moveCamera(this.secondViewCameraPosition, this.secondViewCameraTargetPosition, 3)
            .then(() => this.fireEvent(SeventhSceneEvents.CAMERA_POSITION_CHANGED, SeventhSceneCameraPosition.MACHINE));
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    togglePers = () => {
        this.fireEvent(SeventhSceneEvents.PERS_TOGGLE_PROCESS, true);
        this.animationMixer.clipAction(this.buttonAnimationClip).play();
        const activePers = this.perses.get(this.activePers);
        const activePersIndex = PERSES_ORDER.findIndex(persName => persName === this.activePers)
        const nextPersName = activePersIndex === PERSES_ORDER.length - 1
            ? PERSES_ORDER[0]
            : PERSES_ORDER[activePersIndex + 1];
        const nextPers = activePersIndex === PERSES_ORDER.length - 1
            ? this.perses.get(nextPersName)
            : this.perses.get(nextPersName);

        this.standDown(() => this.standUp());
        this.persDown(
            activePers.gltf.scene,
            () => this.persUp(nextPers.gltf.scene, () => {
                this.activePers = nextPersName;
                this.updateScreenMaterial();
                this.animationMixer.clipAction(this.buttonAnimationClip).stop();
                this.fireEvent(SeventhSceneEvents.PERS_TOGGLE_PROCESS, false);
                this.fireEvent(SeventhSceneEvents.PERS_CHANGED, nextPersName);
            })
        );
    }

    updateScreenMaterial = () => {
        const screen = this.sceneModel.scene.getObjectByName("TV_Screen") as Mesh;
        screen.material = this.perses.get(this.activePers).screenMaterial;
    }

    standUp = (callback?: () => void) => {
        const stand = this.stand.scene;

        interpolateVector(
            stand.position,
            new Vector3(stand.position.x, (stand.position.y + 0.5), stand.position.z),
            0.3,
            (vector) => {
                stand.position.set(vector.x, vector.y, vector.z)
            },
            callback
        );
    }

    standDown = (callback?: () => void) => {
        const stand = this.stand.scene;

        interpolateVector(
            stand.position,
            new Vector3(stand.position.x, (stand.position.y - 0.5), stand.position.z),
            0.3,
            (vector) => {
                stand.position.set(vector.x, vector.y, vector.z)
            },
            callback
        );
    }

    persUp = (pers: Group, callback?: () => void) => {
        interpolateVector(
            pers.position,
            new Vector3(pers.position.x, (pers.position.y + 0.5), pers.position.z),
            0.3,
            (vector) => {
                pers.position.set(vector.x, vector.y, vector.z)
            },
            callback
        );
    }

    persDown = (pers: Group, callback?: () => void) => {
        interpolateVector(
            pers.position,
            new Vector3(pers.position.x, (pers.position.y - 0.5), pers.position.z),
            0.3,
            (vector) => {
                pers.position.set(vector.x, vector.y, vector.z)
            },
            callback
        );
    }

    loadPerses = () => {
        const gltfLoader = new GLTFLoader(this.loadManager);
        const dracoLoader = new DRACOLoader();
        this.setDRACODecoderPath(dracoLoader);
        gltfLoader.setDRACOLoader(dracoLoader);

        const textureLoader = new TextureLoader(this.loadManager);

        PERSES_ORDER.forEach(name => {
            gltfLoader.load(
                `${PERSES_MODELS_PATH}/${name}.glb`,
                glb => {
                    textureLoader.load(`${PERSES_IMAGES_PATH}/${name}.jpg`, texture => {
                        this.perses.set(name, new PersScene(name, glb, texture));
                    })
                }
            );
        });

        gltfLoader.load(
            `${PERSES_MODELS_PATH}/stand.glb`,
            glb => this.stand = glb
        );
    }

    /**
     * Данная сцена ломается при выключенных тенях (?). Для обхода тени для этой сцены включены всегда, но модели не
     * обрабатываются в мобильной версии для их отключения.
     */
    initRenderer(alpha: boolean = false) {
        this.renderer = new WebGLRenderer({
            canvas: this.canvas,
            antialias: false,
            alpha: alpha
        });

        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.setSize(this.viewPortSize.width, this.viewPortSize.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = BasicShadowMap;
    }

    /**
     * Данная сцена ломается при выключенных тенях (?). Для обхода тени для этой сцены включены всегда, но модели не
     * обрабатываются в мобильной версии для их отключения.
     */
    processMeshShadowing(mesh: Mesh<any, any>) {
        if (OSUtils.isMobile()) return;

        if (mesh.name !== "Cube005" && mesh.name !== "Cube019" && mesh.name !== "Cube021") {
            super.processMeshShadowing(mesh);
        }
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.19);

        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xFFE3BC, 1.95);
        directionalLight.position.set(0, 24, 58);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.camera.top = 6;
        directionalLight.shadow.camera.right = 20;
        directionalLight.shadow.camera.bottom = -2;
        directionalLight.shadow.camera.left = 5;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 200;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        const pointLight = new PointLight(0xFFE9B5, 0.42);
        pointLight.castShadow = false;
        pointLight.position.set(16.75, 2.08, 2)
        pointLight.shadow.mapSize.set(1024, 1024);
        pointLight.shadow.bias = -0.00035;
        this.scene.add(pointLight);

        const spotLight = new SpotLight(0xFFE9B5, 0.35);
        spotLight.position.set(11.07, 0.11, -1.02);
        spotLight.target.position.set(-25.3, 2.88, -26.4);
        spotLight.angle = 0.36;
        spotLight.penumbra = 0.32;
        spotLight.decay = 1;
        spotLight.distance = 200;

        spotLight.castShadow = true;
        spotLight.shadow.mapSize.width = 512;
        spotLight.shadow.mapSize.height = 512;
        spotLight.shadow.camera.near = 0.1;
        spotLight.shadow.camera.far = 50;
        spotLight.shadow.focus = 1;
        spotLight.shadow.bias = -0.00024;
        this.scene.add(spotLight);
        this.scene.add(spotLight.target);

        this.isDebug && SeventhServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight, pointLight, spotLight);
    }

    initCamera = () => {
        super.initCamera(27);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && SeventhServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_6);
    }

    moveCameraToNextScene = async () => {
        return super.moveCameraToNextScene(Scenes.SCENE_8);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
