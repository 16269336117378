import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {AnimatePresence} from "framer-motion";
import EighthSceneUI from "./EighthSceneUI";
import EighthSceneCanvas from "./EighthSceneCanvas";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const EighthScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_8 && !dataLoading;

    return (
        <>
            <EighthSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <EighthSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(EighthScene);