import {BackSide, BoxGeometry, LoadingManager, Mesh, MeshBasicMaterial, TextureLoader, Vector3} from "three";
import {Constants} from "../utils/Constants";

const SKYBOX_IMGS_PATH = `${Constants.IMAGES_BASE_PATH}/skybox`;

export class SkyboxSingleton {

    static skybox: Mesh;

    static getSkybox = (loadingManager?: LoadingManager, size: Vector3 = new Vector3(150, 150, 150)) => {
        const textureLoader = new TextureLoader(loadingManager);
        const materialArray = [];
        const skybox_front = textureLoader.load(`${SKYBOX_IMGS_PATH}/front.png`);
        const skybox_back = textureLoader.load(`${SKYBOX_IMGS_PATH}/back.png`);
        const skybox_top = textureLoader.load(`${SKYBOX_IMGS_PATH}/top.png`);
        const skybox_bottom = textureLoader.load(`${SKYBOX_IMGS_PATH}/bottom.png`);
        const skybox_right = textureLoader.load(`${SKYBOX_IMGS_PATH}/right.png`);
        const skybox_left = textureLoader.load(`${SKYBOX_IMGS_PATH}/left.png`);

        materialArray.push(new MeshBasicMaterial({map: skybox_front}));
        materialArray.push(new MeshBasicMaterial({map: skybox_back}));
        materialArray.push(new MeshBasicMaterial({map: skybox_top}));
        materialArray.push(new MeshBasicMaterial({map: skybox_bottom}));
        materialArray.push(new MeshBasicMaterial({map: skybox_right}));
        materialArray.push(new MeshBasicMaterial({map: skybox_left}));

        for (let i = 0; i < 6; i++) {
            materialArray[i].side = BackSide;
        }

        const skyboxGeo = new BoxGeometry(size.x, size.y, size.z);

        return new Mesh(skyboxGeo, materialArray);
    }
}