import React from "react";
import {AnimatedBlockWrapper} from "../../AnimatedBlockWrapper";
import {AnimatePresence} from "framer-motion";
import {openLink} from "../../../utils/UIUtils";
import "./SocialButtons.less";

type SocialButtonsProps = {
    visible: boolean;
}

export const SocialButtons: React.FC<SocialButtonsProps> = (props: SocialButtonsProps) => {
    const {visible} = props;

    const onLinkedinClick = () => {
        openLink("https://www.linkedin.com/company/byte-trading/");
    }

    const onTwitterClick = () => {
        openLink("https://twitter.com/byte_trading");
    }

    return (
        <AnimatePresence>
            {visible &&
            <AnimatedBlockWrapper className="social-buttons">
                <span className="social-button" onClick={onLinkedinClick}>Linkedin</span>
                <span className="social-button" onClick={onTwitterClick}>Twitter</span>
            </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}