import {PersName} from "../service/scene-7/PersScene";

export class Constants {

    // Linkedin links dictionary
    public static linkedinLinksMap: Map<PersName, string> = new Map<PersName, string>([
        [PersName.NFT0170, "https://www.linkedin.com/in/dominikvacikar/"],
        // [PersName.NFT0026, "https://www.linkedin.com/in/harvir-hansra-a0390a103/"],
        [PersName.NFT0009, "https://www.linkedin.com/in/gareth-wilkins-4533701a3/"],
        [PersName.NFT0111, "https://www.linkedin.com/in/nikolay-karnev-7559b440/"],
        [PersName.NFT0146, "https://www.linkedin.com/in/marcosquarci/"],
        [PersName.NFT0197, "https://www.linkedin.com/in/henrybarnett/"],
        [PersName.NFT0214, "https://www.linkedin.com/in/joaodmrodrigues/"],
        // [PersName.NFT0236, "https://www.linkedin.com/in/deepak-guneja/"],
        [PersName.NFT0320, "https://www.linkedin.com/in/jakub-s-75535ba0/"],
        [PersName.NFT0363, "https://www.linkedin.com/in/janhoekman/"],
        [PersName.NFT0428, "https://www.linkedin.com/in/ayushvijaysingh"],
    ]);

    // Assets paths
    public static ASSETS_BASE_PATH = "/assets";
    public static MODELS_BASE_PATH = `${Constants.ASSETS_BASE_PATH}/models`;
    public static IMAGES_BASE_PATH = `${Constants.ASSETS_BASE_PATH}/images`;
    public static SOUNDS_BASE_PATH = `${Constants.ASSETS_BASE_PATH}/sounds`;

    // Links
    public static JOIN_BYTE_LINK = "https://bytetrading.notion.site/Byte-is-Hiring-9a424c7a3ea44515b6d86f9a886add40";

    public static LOADER_DELAY: number = 1500;
}