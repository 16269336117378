import {AmbientLight, DirectionalLight, RectAreaLight, Vector3,} from "three";
import {SceneService} from "../SceneService";
import {CommonEventsService} from "service/CommonEventsService";
import {SecondSceneServiceDebug} from "service/scene-2/SecondSceneServiceDebug";
import {Scenes} from "service/Scenes";
import {Constants} from "../../utils/Constants";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class SecondSceneService extends SceneService {

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_2, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(5.6, 1.3, 7.2);
        this.initialCameraTargetPosition = new Vector3(-0.7, 1.11, -0.62);
        this.cameraFirstZoom = 1.3;
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init();
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_2.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_2.glb`);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.4);
        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xffffff, 1.0);
        directionalLight.position.set(0, 4, 0);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(2048, 2048);
        directionalLight.shadow.camera.top = 10;
        directionalLight.shadow.camera.right = 10;
        directionalLight.shadow.camera.bottom = -10;
        directionalLight.shadow.camera.left = -10;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 15;
        directionalLight.shadow.radius = 7;
        directionalLight.shadow.bias = -0.001;

        const outdoorLight = new RectAreaLight("#FF8F60", 3, 3.5, 1.9);
        outdoorLight.position.set(-11.5, 3.2, -0.3);
        outdoorLight.rotation.y = -1.4;
        this.scene.add(outdoorLight);

        this.scene.add(directionalLight);

        this.isDebug && SecondSceneServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight, outdoorLight);
    }

    initCamera = () => {
        super.initCamera(33)
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && SecondSceneServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    moveCameraToNextScene = async () => {
        return super.moveCameraToNextScene(Scenes.SCENE_3);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    moveCameraToFirstScene() {
        return this.moveCameraToPreviousScene();
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
