import React, {useState} from "react";
import classNames from "classnames";

type JoinByteButton = {
    className?: string;
    onClick?: () => void;
}

export const JoinByteButton: React.FC<JoinByteButton> = (props: JoinByteButton) => {
    const {className, onClick} = props;
    const [hover, setHover] = useState<boolean>(false);

    const resultClassName = classNames("join-byte-button", {"hovered": hover}, className);

    return (
        <div className={resultClassName}>
            <span className="icon"
                  onClick={onClick}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}/>
            <span className="content">Join Byte</span>
        </div>
    );
}
