import React, {useContext} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {Vector3} from "three";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import {EighthSceneService} from "../../service/scene-8/EightSceneService";
import {AnimatedBlockWrapper} from "../AnimatedBlockWrapper";
import {CircleButton} from "../common/circle-button/CircleButton";
import HtmlFor3D from "../HtmlFor3D";
import {openLink} from "../../utils/UIUtils";
import "./EighthSceneUI.less";

const EighthSceneUI: React.FC = () => {
    const {currentSceneService} = useContext<AppContext>(GlobalAppContext);

    const toNextSceneClick = () => {
        (currentSceneService as EighthSceneService).moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as EighthSceneService).moveCameraToPreviousScene();
    }

    return (
        <SceneUILayoutWrapper className="eighth-scene-ui">
            <MainControlButtons
                onHomeButtonClick={() => (currentSceneService as EighthSceneService).moveCameraToFirstScene()}/>
            <ScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}>
            </ScenesControlButtons>

            <HtmlFor3D sceneService={currentSceneService} position={new Vector3(4.25, 1.83, -1.195)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={() => openLink("https://bytetrading.notion.site/Byte-is-Hiring-9a424c7a3ea44515b6d86f9a886add40")}/>
                </AnimatedBlockWrapper>
            </HtmlFor3D>

            <HtmlFor3D sceneService={currentSceneService} position={new Vector3(5.68, 1.83, -1.195)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={() => openLink("https://bytetrading.notion.site/Trading-e41819a6b1574ab3814d5606b9b14e3a")}/>
                </AnimatedBlockWrapper>
            </HtmlFor3D>

            <HtmlFor3D sceneService={currentSceneService} position={new Vector3(6.85, 1.83, -1.195)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={() => openLink("https://bytetrading.notion.site/Operations-1810fbdec6bb40a6af1180a7f5ad72eb")}/>
                </AnimatedBlockWrapper>
            </HtmlFor3D>
        </SceneUILayoutWrapper>
    );
}

export default observer(EighthSceneUI);
