import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import Ticker from "react-ticker";
import classNames from "classnames";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {AppContext, GlobalAppContext} from "../../app-context/AppContext";
import {ConditionShow} from "../ConditionShow";
import {Button} from "../common/button/Button";
import {AnimatePresence} from "framer-motion";
import {AnimatedBlockWrapper} from "../AnimatedBlockWrapper";
import {SocialButtons} from "../common/scial-buttons/SocialButtons";
import "./LoaderSceneUI.less";

const LoaderSceneUI: React.FC = () => {
    const context = useContext<AppContext>(GlobalAppContext);
    const {firstLoading, currentSceneService} = context;
    const [localFirstLoading, setLocalFirstLoading] = useState<boolean>(undefined);
    const [continueWasClicked, setContinueWasClicked] = useState<boolean>(context.userContinueAction && firstLoading);

    // workaround - read real value only after next loading;
    useEffect(() => {
        setLocalFirstLoading(firstLoading);
    }, []);

    const onContinueClick = () => {
        context.waitUserContinueAction = false;
        context.userContinueAction = true;
        setContinueWasClicked(true);
        currentSceneService?.continue();
    }

    const className = classNames("loader-scene-ui", {"non-first-loading": !localFirstLoading});

    return (
        <SceneUILayoutWrapper startDelay={0} className={className}>
            <SocialButtons visible={context.waitUserContinueAction}/>
            <ConditionShow condition={localFirstLoading}>
                <span className="loader-logo"/>
            </ConditionShow>
            <ContinueButton onClick={onContinueClick} visible={context.waitUserContinueAction}/>
            <LoaderTicker visible={!context.waitUserContinueAction && !continueWasClicked} localFirstLoading={localFirstLoading}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(LoaderSceneUI);

type ContinueButtonProps = {
    onClick: () => void;
    visible: boolean;
}

const ContinueButton: React.FC<ContinueButtonProps> = (props: ContinueButtonProps) => {
    const {onClick, visible} = props;
    const [clicked, setClicked] = useState<boolean>(false);

    const className = classNames("continue-button", {"clicked": clicked});

    const makeClicked = () => {
        setClicked(true);
    }

    return (
        <AnimatePresence>
            {visible &&
                <AnimatedBlockWrapper startDelay={0.3}>
                    <Button className={className} onClick={onClick} onMouseDown={makeClicked} onTouchStart={makeClicked} type={clicked ? "main" : "default"}>Enter</Button>
                </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}

type LoaderTickerProps = {
    visible: boolean;
    localFirstLoading: boolean;
}

const LoaderTicker: React.FC<LoaderTickerProps> = (props: LoaderTickerProps) => {
    const {visible, localFirstLoading} = props;

    return (
        <AnimatePresence>
            {visible &&
                <AnimatedBlockWrapper className="led-line">
                    <ConditionShow condition={localFirstLoading}>
                        <Ticker speed={20}>
                            {() => <div className="text" style={{paddingRight: "2em"}}>Almost there</div>}
                        </Ticker>
                    </ConditionShow>

                    <ConditionShow condition={!localFirstLoading}>
                        <span className="up-arrow"/>
                        <div className="clocks">
                            <div className="hour-arrow"/>
                            <div className="arrow-placeholder"/>
                            <div className="minute-arrow"/>
                        </div>
                        <span className="down-arrow"/>
                    </ConditionShow>
                </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}
