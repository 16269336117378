import React, {PropsWithChildren} from "react";
import {motion, MotionStyle} from "framer-motion";
import classNames from "classnames";

type ButtonWrapperProps = {
    onClick?: () => void;
    className?: string;
    style?: MotionStyle;
    startDelay?: number;
}

export const AnimatedBlockWrapper: React.FC<PropsWithChildren<ButtonWrapperProps>> = (props: PropsWithChildren<ButtonWrapperProps>) => {
    const {children, className, onClick, style, startDelay = 0} = props;
    const resultClassName = classNames("animated-block-wrapper", className)

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1, transition: {delay: startDelay}}}
            exit={{opacity: 0}}
            onClick={onClick}
            style={style}
            className={resultClassName}>
            {children}
        </motion.div>
    );
}