import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {AnimatePresence} from "framer-motion";
import NinthSceneUI from "./NinthSceneUI";
import NinthSceneCanvas from "./NinthSceneCanvas";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const NinthScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_9 && !dataLoading;

    return (
        <>
            <NinthSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <NinthSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(NinthScene);