import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {AnimatePresence} from "framer-motion";
import SeventhSceneCanvas from "./SeventhSceneCanvas";
import SeventhSceneUI from "./SeventhSceneUI";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const SeventhScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_7 && !dataLoading;

    return (
        <>
            <SeventhSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <SeventhSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(SeventhScene);