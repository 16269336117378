import {Object3D, PerspectiveCamera, Scene, Vector2, Vector3, WebGLRenderer} from "three";
import {v4 as uuid} from "uuid";

/**
 * Класс содержащий логику по добавлению и обработке объектов-пустышек для дальнейшего расчёта 2D координат в проекции
 * на вьюпорт. Данный класс позволяет привязывать HTML-элемент к координате на канвасе.
 */
export class SceneElementsCoordinates {

    camera: PerspectiveCamera;

    renderer: WebGLRenderer;

    scene: Scene;

    /**
     * Список всех объектов-пустышек
     */
    elementsCoordinates: ElementCoordinateObject[] = [];

    constructor(camera: PerspectiveCamera, renderer: WebGLRenderer, scene: Scene) {
        this.camera = camera;
        this.renderer = renderer;
        this.scene = scene;
    }

    /**
     * Добавляет объект-пустышку в сцену для дальнейшего просчёта и проекции координат под позиционирование
     * HTML-элементов. ID генерируется для возможности получения/удаления элемента. В теории должно увеличивать
     * перформанс при пересчёте координат, т.к. алгоритмы зачастую перерасчитывают ВСЕ объекты в массиве, соответственно
     * его стоит чистить.
     */
    addElement = (x = 0, y = 0, z = 0, elementId = uuid()) => {
        const elementObject = new Object3D();
        elementObject.position.set(x, y, z);
        const elementCoordinateObject = new ElementCoordinateObject(elementObject, elementId);
        elementCoordinateObject.elementCoordinate = this.getCoordinates(elementObject);
        this.scene.add(elementObject);
        this.elementsCoordinates.push(elementCoordinateObject);
        return elementCoordinateObject;
    }

    getElement = (elementId: string) => {
        return this.elementsCoordinates.find(b => b.id === elementId);
    }

    removeElement = (elementId: string) => {
        this.elementsCoordinates = this.elementsCoordinates.filter(elem => elem.id !== elementId);
    }

    /**
     * Перерасчитывает координаты проекции на вьюпорт для каждого объекта
     */
    refreshCoordinates = () => {
        this.elementsCoordinates.forEach(elem => elem.elementCoordinate = this.getCoordinates(elem.element3DObject));
    }

    /**
     * Возвращает расчитанные 2D-координаты для проекции и привязки HTML-элемента к сцене.
     */
    getCoordinates = (element: Object3D): Vector2 => {
        const canvas: HTMLCanvasElement = this.renderer.domElement;
        element.updateMatrixWorld()
        this.camera.updateMatrixWorld();

        const screenVector = new Vector3();
        element.localToWorld(screenVector);
        screenVector.project(this.camera);

        const positionX = Math.round((screenVector.x + 1) * canvas.offsetWidth / 2);
        const positionY = Math.round((1 - screenVector.y) * canvas.offsetHeight / 2);

        return new Vector2(positionX, positionY);
    }
}

export class ElementCoordinateObject {

    id: string;

    element3DObject: Object3D;

    elementCoordinate: Vector2;

    constructor(element3DObject: Object3D, id: string) {
        this.element3DObject = element3DObject;
        this.id = id;
    }
}
