import React, {CSSProperties, PropsWithChildren} from "react";
import classNames from "classnames";
import "./Button.less";

type ButtonProps = {
    className?: string;
    onClick?: () => void;
    onMouseDown?: () => void;
    onTouchStart?: () => void;
    style?: CSSProperties;
    type?: ButtonType;
    arrowType?: ButtonArrowType;
}

export type ButtonType = "default" | "main";
export type ButtonArrowType = "left" | "up" | "down";

export const Button: React.FC<PropsWithChildren<ButtonProps>> = (props: PropsWithChildren<ButtonProps>) => {
    const {className, children, onClick, onMouseDown, onTouchStart, style, type = "default", arrowType} = props;
    const resultClassName = classNames("button", type, className);
    const arrowResultClassName = classNames("arrow-icon", arrowType)

    return (
        <button className={resultClassName} onClick={onClick} style={style} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
            {arrowType && <span className={arrowResultClassName}/>}
            {children}
        </button>
    );
}