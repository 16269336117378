import {AmbientLight, DirectionalLight, Mesh, Vector3,} from "three";
import {SceneService} from "../SceneService";
import {CommonEventsService} from "service/CommonEventsService";
import {CitySceneEvents} from "service/scene-1/CitySceneEvents";
import {CitySceneCameraPosition} from "service/scene-1/CitySceneCameraPosition";
import {Scenes} from "service/Scenes";
import {Constants} from "../../utils/Constants";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class CitySceneService extends SceneService<CitySceneEvents> {

    secondViewCameraPosition: Vector3 = new Vector3(14, 2.81, 33.44);

    secondViewCameraTargetPosition: Vector3 = new Vector3(5, 2.81, -2.5);

    excludeFromVertexNormalsComputing: string[] = ["Cube184", "scaffolding", "scaffolding002", "scaffolding003"];

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.CITY_SCENE, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(12, 18, 39.24);
        this.initialCameraTargetPosition = new Vector3(5, -5.76, -10.89);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init(false);
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_1.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/city_scene.glb`);
    }

    moveCameraToFirstView = async () => {
        this.moveCamera(this.initialCameraPosition, this.initialCameraTargetPosition, 3)
            .then(() => this.fireEvent(CitySceneEvents.CAMERA_POSITION_CHANGED, CitySceneCameraPosition.TOP));
    }

    moveCameraToSecondView = async () => {
        this.moveCamera(this.secondViewCameraPosition, this.secondViewCameraTargetPosition, 3)
            .then(() => this.fireEvent(CitySceneEvents.CAMERA_POSITION_CHANGED, CitySceneCameraPosition.BOTTOM));
    }

    moveCameraToNextScene = async () => {
        this.animationMixer.clipAction(this.sceneModel.animations.find(a => a.name === "opening_the_left_door")).fadeOut(2)
        this.animationMixer.clipAction(this.sceneModel.animations.find(a => a.name === "opening_the_right_door")).fadeOut(2)
        return super.moveCameraToNextScene(
            Scenes.SCENE_2,
            new Vector3(6.27, 1, -0.1),
            new Vector3(5, 0.2, -6.27),
            3
        );
    }

    initSceneModel = () => {
        super.initSceneModel();
        this.sceneModel.scene.scale.set(0.1, 0.1, 0.1);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.54);

        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight("#FF8F60", 1.8);
        directionalLight.position.set(50, 24, 50);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(2048, 2048);
        directionalLight.shadow.camera.top = 30;
        directionalLight.shadow.camera.right = 28;
        directionalLight.shadow.camera.bottom = -10;
        directionalLight.shadow.camera.left = -21;
        directionalLight.shadow.camera.near = 30;
        directionalLight.shadow.camera.far = 124;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        // this.isDebug && CitySceneServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight);
    }

    initCamera = () => {
        super.initCamera(36.7)
    }

    initCameraControls = () => {
        super.initCameraControls();
        // this.isDebug && CitySceneServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    processMeshShadowing(mesh: Mesh<any, any>) {
        super.processMeshShadowing(mesh, true);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
