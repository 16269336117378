import React from "react";
import "./TurnPhone.less";

export const TurnPhone: React.FC = () => {
    return(
        <div className="turn-phone">
            <span className="text">
                Please turn your phone to continue
            </span>
            <span className="placeholder"/>
        </div>
    );
}