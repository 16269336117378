import {AmbientLight, CameraHelper, DirectionalLight, DirectionalLightHelper, PerspectiveCamera, Scene} from "three";
import {GUI} from "dat.gui";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

// const gui_3 = new GUI({name: "EIGHTH SCENE DEBUG"})

/**
 * Класс для добавления инструментов дебага сцены. Создан для чистоты основной логики.
 */
export class EighthServiceDebug {

    static ambLightDebug = (scene: Scene, ambientLight: AmbientLight) => {
        /*const ambientLightFolder = gui_3.addFolder('Ambient light');
        ambientLightFolder.add(ambientLight, 'intensity').min(0).max(5).step(0.001);*/
    }

    static initDirLight1 = (scene: Scene, dirLight: DirectionalLight, num: number) => {
        /*const dirLightHelper = new DirectionalLightHelper(dirLight, 10);
        scene.add(dirLightHelper);
        const helper = new CameraHelper(dirLight.shadow.camera);
        scene.add(helper);

        const data = {
            color: dirLight.color.getHex(),
            mapsEnabled: true,
        }

        const directionalLightFolder = gui_3.addFolder('Directional light ' + num)
        directionalLightFolder.addColor(data, 'color').onChange(() => {
            dirLight.color.setHex(Number(data.color.toString().replace('#', '0x')))
        })
        directionalLightFolder.add(dirLight, 'intensity', 0, 5, 0.01)

        directionalLightFolder
            .add(dirLight.shadow.camera, 'left', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'right', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'top', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'bottom', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'near', 0.1, 200, 0.01)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'far', 0.1, 200, 0.01)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'bias', -0.001, 0.001).step(0.00001)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'radius', 0, 10).step(0.001)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder.add(dirLight.position, 'x', -50, 100, 0.01)
        directionalLightFolder.add(dirLight.position, 'y', -50, 100, 0.01)
        directionalLightFolder.add(dirLight.position, 'z', -50, 100, 0.01)*/

    }

    static initCameraDebug = (scene: Scene, camera: PerspectiveCamera, controls: OrbitControls) => {
        /*const cameraFolder = gui_3.addFolder('Scene 3 Camera');
        cameraFolder.add(camera.position, "x", -100, 100, 0.01);
        cameraFolder.add(camera.position, "y", -100, 100, 0.01);
        cameraFolder.add(camera.position, "z", -100, 100, 0.01);
        cameraFolder
            .add(camera, "fov", 0, 45, 0.01)
            .onChange(() => camera.updateProjectionMatrix());
        cameraFolder.add(controls.target, "x", -100, 100, 0.01);
        cameraFolder.add(controls.target, "y", -100, 100, 0.01);
        cameraFolder.add(controls.target, "z", -100, 100, 0.01);*/
    }
}