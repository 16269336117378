import React, {useContext} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {InfoPanel, InfoPanelHeader, InfoPanelText} from "../InfoPanel";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import "./SixthScene.less"
import {SixthSceneService} from "../../service/scene-6/SixthSceneService";

const SixthSceneUI: React.FC = () => {
    const {currentSceneService} = useContext<AppContext>(GlobalAppContext);

    const toNextSceneClick = () => {
        (currentSceneService as SixthSceneService).moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as SixthSceneService).moveCameraToPreviousScene();
    }

    return (
        <SceneUILayoutWrapper className="sixth-scene-ui">
            <InfoPanel>
                <InfoPanelHeader>
                    We build high-speed risk management systems that seamlessly deal with any market environment
                </InfoPanelHeader>
            </InfoPanel>
            <MainControlButtons onHomeButtonClick={() => (currentSceneService as SixthSceneService).moveCameraToFirstScene()}/>
            <ScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(SixthSceneUI);
