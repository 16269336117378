import React, {useContext, useEffect, useState} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {AnimatePresence} from "framer-motion";
import {Vector3} from "three";
import classNames from "classnames";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import HtmlFor3D from "../HtmlFor3D";
import {AnimatedBlockWrapper} from "../AnimatedBlockWrapper";
import {SeventhSceneService} from "../../service/scene-7/SeventhSceneService";
import {SeventhSceneCameraPosition} from "../../service/scene-7/SeventhSceneCameraPosition";
import {SeventhSceneEvents} from "../../service/scene-7/SeventhSceneEvents";
import {CircleButton} from "../common/circle-button/CircleButton";
import {ScenesControlButtons as IScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {PersName} from "../../service/scene-7/PersScene";
import {ConditionShow} from "../ConditionShow";
import {openLink} from "../../utils/UIUtils";
import {Constants} from "../../utils/Constants";
import "./SeventhSceneUI.less";

const SeventhSceneUI: React.FC = () => {
    const {currentSceneService, cameraIsMoving} = useContext<AppContext>(GlobalAppContext);
    const [cameraPosition, setCameraPosition] = useState<SeventhSceneCameraPosition>(SeventhSceneCameraPosition.MAIN);
    const [persTogglerActive, setPersTogglerActive] = useState<boolean>(true);
    const [activePers, setActivePers] = useState<PersName>(null);
    const seventhSceneService = (currentSceneService as SeventhSceneService);

    useEffect(() => {
        if (currentSceneService) {

            setActivePers(seventhSceneService.activePers);

            const removeCameraPositionChangingConsumer = seventhSceneService.consumeOnEvent(
                SeventhSceneEvents.CAMERA_POSITION_CHANGED,
                position => setCameraPosition(position)
            );

            const removePersTogglerConsumer = seventhSceneService.consumeOnEvent(
                SeventhSceneEvents.PERS_TOGGLE_PROCESS,
                status => setPersTogglerActive(!status)
            );

            const removeActivePersChangingConsumer = seventhSceneService.consumeOnEvent(
                SeventhSceneEvents.PERS_CHANGED,
                persName => setActivePers(persName)
            );

            return () => {
                removeCameraPositionChangingConsumer();
                removePersTogglerConsumer();
                removeActivePersChangingConsumer();
            };
        }
    }, [currentSceneService]);

    const toNextSceneClick = () => {
        seventhSceneService.moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        seventhSceneService.moveCameraToPreviousScene();
    }

    const onMachineClick = () => {
        seventhSceneService.moveCameraToMachineView();
    }

    const onToggleClick = () => {
        persTogglerActive && seventhSceneService.togglePers();
    }

    const onMainViewClick = () => {
        seventhSceneService.moveCameraToMainView();
    }

    const onLinkedinClick = () => {
        persTogglerActive && openLink(Constants.linkedinLinksMap.get(activePers));
    }

    const isMachineView = cameraPosition === SeventhSceneCameraPosition.MACHINE;
    const showMachineButton = !cameraIsMoving && !isMachineView;
    const showMainViewButton = !cameraIsMoving && isMachineView;
    const resultClass = classNames("seventh-scene-ui", {"machine-view": isMachineView})

    return (
        <SceneUILayoutWrapper className={resultClass}>
            <MachineButton seventhSceneService={seventhSceneService}
                           onClick={onMachineClick}
                           visible={showMachineButton}/>

            <ScenesControlButtons
                seventhSceneService={seventhSceneService}
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}
                visible={!cameraIsMoving}
                machineView={isMachineView}/>

            <TogglePersButton seventhSceneService={seventhSceneService}
                              onClick={onToggleClick}
                              visible={showMainViewButton}/>

            <LinkedinLinkButton seventhSceneService={seventhSceneService}
                                onClick={onLinkedinClick}
                                visible={showMainViewButton}/>

            <MainViewButton seventhSceneService={seventhSceneService}
                            onClick={onMainViewClick}
                            visible={showMainViewButton}/>

            <MainControlButtons soundButtonVisible={!cameraIsMoving}
                                homeButtonVisible={!cameraIsMoving}
                                joinByteButtonVisible={!cameraIsMoving}
                                onHomeButtonClick={() => seventhSceneService.moveCameraToFirstScene()}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(SeventhSceneUI);

type MachineButtonProps = {
    seventhSceneService: SeventhSceneService;
    onClick: () => void;
    visible: boolean;
}

const MachineButton: React.FC<MachineButtonProps> = (props: MachineButtonProps) => {
    const {seventhSceneService, onClick, visible} = props;

    return (
        <AnimatePresence>
            {visible &&
            <HtmlFor3D sceneService={seventhSceneService} position={new Vector3(6.5, 1.5, -1.1)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={onClick}>Meet the team</CircleButton>
                </AnimatedBlockWrapper>
            </HtmlFor3D>}
        </AnimatePresence>
    );
}

const TogglePersButton: React.FC<MachineButtonProps> = (props: MachineButtonProps) => {
    const {seventhSceneService, onClick, visible} = props;

    return (
        <AnimatePresence>
            {visible &&
            <HtmlFor3D sceneService={seventhSceneService} position={new Vector3(6.5, 0.30, -1.195)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={onClick} className="toggle-pers-button">Push the button</CircleButton>
                </AnimatedBlockWrapper>
            </HtmlFor3D>}
        </AnimatePresence>
    );
}

const MainViewButton: React.FC<MachineButtonProps> = (props: MachineButtonProps) => {
    const {onClick, visible, seventhSceneService} = props;

    return (
        <AnimatePresence>
            {visible &&
            <HtmlFor3D sceneService={seventhSceneService} position={new Vector3(6.5, 1.45, -1.5)}>
                <AnimatedBlockWrapper>
                    <CircleButton onClick={onClick}>Back to the room</CircleButton>
                </AnimatedBlockWrapper>
            </HtmlFor3D>}
        </AnimatePresence>
    );
}

const LinkedinLinkButton: React.FC<MachineButtonProps> = (props: MachineButtonProps) => {
    const {onClick, visible, seventhSceneService} = props;

    return (
        <HtmlFor3D sceneService={seventhSceneService} position={new Vector3(6.5, 1.165, -3.02)}>
            <ConditionShow condition={visible}>
                <div className="linkedin-link" onClick={onClick}/>
            </ConditionShow>
        </HtmlFor3D>
    );
}

type SceneMainControlButtonsProps = {
    seventhSceneService: SeventhSceneService;
    toNextSceneClick: () => void;
    toPreviousSceneClick: () => void;
    machineView: boolean;
    visible: boolean;
}

const ScenesControlButtons: React.FC<SceneMainControlButtonsProps> = (props: SceneMainControlButtonsProps) => {
    const {seventhSceneService, toNextSceneClick, toPreviousSceneClick, machineView, visible} = props;

    return (
        machineView
            ? <HtmlFor3D sceneService={seventhSceneService} position={new Vector3(6.5, 1.45, -1.9)}>
                <IScenesControlButtons
                    toNextSceneClick={toNextSceneClick}
                    toPreviousSceneClick={toPreviousSceneClick}
                    visible={visible}/>
            </HtmlFor3D>
            : <IScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}
                visible={visible}/>
    );
}
