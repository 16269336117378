import React, {PropsWithChildren} from "react";

type ConditionShowProps = {
    condition: boolean;
}

export const ConditionShow: React.FC<PropsWithChildren<ConditionShowProps>> = (props:PropsWithChildren<ConditionShowProps>) => {
    const {condition, children} = props;

    return(
        condition ? <>{children}</> : null
    );
}