import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {ScenesEvents} from "service/SceneEvents";
import {Vector3} from "three";
import {SceneService} from "service/SceneService";

type HtmlFor3DProps = {
    position: Vector3;
    sceneService: SceneService;
}

/**
 * Компонент для добавления HTML элементов на сцену. Логика завязана на работу с классом {@link SceneElementsCoordinates}
 * и списком его экземпляров в сцене. При создании добавляет элемент в список экземпляров {@link SceneElementsCoordinates}
 * сцены и реагирует на изменение масштаба вьюпорта для определения новых координат.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const HtmlFor3D: React.FC<PropsWithChildren<HtmlFor3DProps>> = (props: PropsWithChildren<HtmlFor3DProps>) => {
    const {position, sceneService, children} = props;
    const {commonEventsService} = useContext<AppContext>(GlobalAppContext);

    const [elemPositionState, setElemPositionState] = useState({x: 0, y: 0});

    const setElementPositionFromService = (elementId: string) => {
        const element = sceneService.sceneElementsCoordinates.getElement(elementId);

        // Компонент иногда запоминает старые ID и достаёт несуществующие элементы - ???
        if (!element) return;

        setElemPositionState({
            x: element.elementCoordinate.x,
            y: element.elementCoordinate.y
        });
    }

    useEffect(() => {
        if (sceneService) {
            const elementCoordinates = sceneService.sceneElementsCoordinates.addElement(
                position.x,
                position.y,
                position.z
            );

            setElementPositionFromService(elementCoordinates.id);

            const removeUpdateConsumer = commonEventsService.consumeOnEvent(
                ScenesEvents.HTML_ELEMENTS_COORDINATES_UPDATED,
                () => setElementPositionFromService(elementCoordinates.id)
            );

            return () => {
                sceneService.sceneElementsCoordinates.removeElement(elementCoordinates.id);
                removeUpdateConsumer();
            }
        }
    }, [sceneService]);

    return (
        <div style={{position: "absolute", left: elemPositionState.x, top: elemPositionState.y}}>
            {children}
        </div>
    );
}

export default observer(HtmlFor3D);
