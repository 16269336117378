import React, {PropsWithChildren} from "react";
import "./InfoPanel.less";

export const InfoPanel: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    return (
        <div className="scene-info-panel">
            <div className="background-gradient"/>
            {props.children}
        </div>
    );
}

export const InfoPanelHeader: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    return (
        <div className="header">{props.children}</div>
    );
}

export const InfoPanelText: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    return (
        <div className="text">{props.children}</div>
    );
}
