import {AmbientLight, DirectionalLight, Mesh, Vector3} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {NinthSceneDebug} from "./NinthSceneDebug";
import {NinthSceneEvents} from "./NinthSceneEvents";
import {NinthSceneCameraPosition} from "./NinthSceneCameraPosition";
import {Constants} from "../../utils/Constants";
import {SkyboxSingleton} from "../SkyboxSingleton";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class NinthSceneService extends SceneService<NinthSceneEvents> {

    secondViewCameraPosition: Vector3 = new Vector3(41.9, 37.09, -42.01);

    secondViewCameraTargetPosition: Vector3 = new Vector3(19.85, 40.53, -40.15);

    excludeFromVertexNormalsComputing = ["Cube007", "Building_railing003", "Cube011", "Building_text", "Cube017", "Cube402", "Cube319", "Cube094", "Cube411", "Plane006", "Plane007"]

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_9, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(30.68, 52.56, -23.08);
        this.initialCameraTargetPosition = new Vector3(18.56, 47.17, -32.84);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init();
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_9.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_9.glb`);
    }

    processMeshShadowing(mesh: Mesh<any, any>) {
        super.processMeshShadowing(mesh, true);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.76);

        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xFFD7B4, 1.62);
        directionalLight.position.set(58.24, 43.43, 21.85);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.camera.top = 46;
        directionalLight.shadow.camera.right = 56;
        directionalLight.shadow.camera.bottom = 15.8;
        directionalLight.shadow.camera.left = 26.7;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 200;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        this.isDebug && NinthSceneDebug.ambLightDebug(this.scene, ambientLight);
        this.isDebug && NinthSceneDebug.initDirLight(this.scene, directionalLight, 1);
    }

    initCamera = () => {
        super.initCamera(27.99);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && NinthSceneDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    initSkybox() {
        this.skybox = SkyboxSingleton.getSkybox(this.loadManager, new Vector3(90, 90, 90));
        this.scene.add(this.skybox);
        this.skybox.position.set(0, 5.5, -57.21);
        this.skybox.rotation.set(0, 1.04, 0);
        this.isDebug && NinthSceneDebug.initSkyboxDebug(this.skybox);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_8);
    }

    moveCameraToNextScene = async () => {
        console.warn("Doesn't have next scene!");
    }

    moveCameraToFirstView = async () => {
        this.moveCamera(this.initialCameraPosition, this.initialCameraTargetPosition, 3)
            .then(() => this.fireEvent(NinthSceneEvents.CAMERA_POSITION_CHANGED, NinthSceneCameraPosition.TOP));
    }

    moveCameraToSecondView = async () => {
        this.moveCamera(this.secondViewCameraPosition, this.secondViewCameraTargetPosition, 3)
            .then(() => this.fireEvent(NinthSceneEvents.CAMERA_POSITION_CHANGED, NinthSceneCameraPosition.BOTTOM));
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
