import React, {useCallback, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {Scenes} from "service/Scenes";
import classNames from "classnames";
import {sceneVariants, SceneVariantsTypes} from "utils/AnimationUtils";
import {motion} from "framer-motion";
import {SixthSceneService} from "../../service/scene-6/SixthSceneService";

type SixthSceneCanvasProps = {
    visible: boolean;
}

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const SixthSceneCanvas: React.FC<SixthSceneCanvasProps> = (props: SixthSceneCanvasProps) => {
    const {
        currentScene,
        commonEventsService,
        currentSceneService,
        setCurrentSceneService,
        useSound,
        sceneChangingProcess
    } = useContext<AppContext>(GlobalAppContext);

    const [forceHide, setForceHide] = useState<boolean>(false);

    const canvasCallback = useCallback((canvas: HTMLCanvasElement) => {
        if (canvas && currentScene === Scenes.SCENE_6) {
            setCurrentSceneService(new SixthSceneService(canvas, commonEventsService, useSound));
        }
    }, [currentScene]);

    useEffect(() => {
        return currentSceneService ? currentSceneService.clearContext : undefined;
    }, [currentSceneService]);

    useEffect(() => {
        !!sceneChangingProcess && setTimeout(() => setForceHide(true), 1200);
    }, [sceneChangingProcess]);

    const className = classNames("scene-canvas", "sixth-scene-canvas")

    return (
        <motion.canvas
            className={className}
            ref={canvasCallback}
            variants={sceneVariants}
            initial={SceneVariantsTypes.HIDE}
            animate={props.visible && !forceHide ? SceneVariantsTypes.SHOW : SceneVariantsTypes.HIDE}/>
    );
}

export default observer(SixthSceneCanvas);