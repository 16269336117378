import {AmbientLight, AnimationMixer, DirectionalLight, Mesh, Object3D,} from "three";
import {SceneService} from "../SceneService";
import {CommonEventsService} from "service/CommonEventsService";
import {LoaderSceneServiceDebug} from "service/loader-scene/LoaderSceneServiceDebug";
import {Constants} from "../../utils/Constants";

/**
 * Основной класс для обработки 3D загрузчика.
 */
export class LoaderSceneService extends SceneService {

    lastPersonToggleTime: number;

    perses: Object3D[] = [];

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean) {
        super(commonEventsService, canvas, "LOADER SCENE", useSound);
        this.isDebug = false;
        this.init();
        this.startRendering();
    }

    init = () => {
        super.init(false);
        this.initLights();
        this.loadSceneModel(
            `${Constants.MODELS_BASE_PATH}/loader_scene.glb`,
            false,
            this.initSceneModel
        );
    }

    playAmbient = () => {
        // ... do nothing
    }

    stopAmbient = () => {
        // ... do nothing
    }

    // TODO: Refactoring with inhiretance
    initSceneModel = () => {
        this.sceneModel.scene.traverse(child => {
            child.frustumCulled = false;

            if (child instanceof Mesh) {
                child.receiveShadow = true;
                child.castShadow = true;
                child.geometry?.computeVertexNormals();
            }
        });

        this.sceneModel.scene.children[0].children.forEach((child, index) => {
            if (child.name !== "spine") {
                (index > 1) && (child.visible = false);
                this.perses.push(child);
            }
        });

        this.animationMixer = new AnimationMixer(this.sceneModel.scene);
        this.scene.add(this.sceneModel.scene);
        this.startAnimations();

        this.clock.start();
        this.lastPersonToggleTime = this.clock.oldTime;

        this.processSceneElementsCoordinatesRefreshing();
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 1);

        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xffffff, 2);
        directionalLight.position.set(50, 24, 50);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.camera.top = 3;
        directionalLight.shadow.camera.right = 3;
        directionalLight.shadow.camera.bottom = -2;
        directionalLight.shadow.camera.left = -3;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 150;
        directionalLight.shadow.bias = -0.0005;

        this.scene.add(directionalLight);

        // this.isDebug && LoaderSceneServiceDebug.initLightsDebug(this.scene, directionalLight, ambientLight);
    }

    initCamera = () => {
        super.initCamera();
        this.camera.position.set(-2.6, 3.21, 3.92);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.cameraControls.target.set(-0.04, 1.03, 0.11);
        // this.isDebug && LoaderSceneServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    initRenderer = () => {
        super.initRenderer(true);
    }

    onStartRendering(): void {
        if (!!this.sceneModel) {
            this.startAnimations();
        }
    };

    rotateScene = () => {
        this.sceneModel?.scene.rotateY(0.01);
    }

    togglePerson = () => {
        if ((this.lastPersonToggleTime + 1000) > this.clock.oldTime || !this.sceneModel) {
            return;
        }

        const childIndex = this.perses.findIndex(child => child.visible);
        this.perses[childIndex].visible = false;

        const toggleIndex = (childIndex === this.perses.length - 1) ? 0 : childIndex + 1;
        this.perses[toggleIndex].visible = true;

        this.lastPersonToggleTime = this.clock.oldTime;
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.rotateScene();
        this.togglePerson();
        this.renderer.render(this.scene, this.camera);
    }
}
