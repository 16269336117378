import {AmbientLight, DirectionalLight, Mesh, Vector3} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {EighthServiceDebug} from "./EighthServiceDebug";
import {Constants} from "../../utils/Constants";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class EighthSceneService extends SceneService {

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_8, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(5.87, 1.28, 5.49);
        this.initialCameraTargetPosition = new Vector3(5.95, 1.03, -2.53);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init(false);
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_8.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_8.glb`);
    }

    processMeshShadowing(mesh: Mesh<any, any>) {
        if (mesh.name !== "ceiling") {
            super.processMeshShadowing(mesh);
        }
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.43);

        this.scene.add(ambientLight);

        const dirLight1 = new DirectionalLight(0xFFD1C9, 0.26);
        dirLight1.position.set(-17.91, 2.79, 11.87);
        dirLight1.castShadow = true;
        dirLight1.shadow.mapSize.set(2048, 2048);
        dirLight1.shadow.camera.top = 10;
        dirLight1.shadow.camera.right = 10;
        dirLight1.shadow.camera.bottom = -5;
        dirLight1.shadow.camera.left = -3;
        dirLight1.shadow.camera.near = 24;
        dirLight1.shadow.camera.far = 200;
        dirLight1.shadow.bias = -0.001;

        this.scene.add(dirLight1);

        const dirLight2 = new DirectionalLight(0xffffff, 0.47);
        dirLight2.position.set(30.73, 0, 12.53);
        dirLight2.castShadow = true;
        dirLight2.shadow.mapSize.set(2048, 2048);
        dirLight2.shadow.camera.top = 6;
        dirLight2.shadow.camera.right = 13;
        dirLight2.shadow.camera.bottom = -5;
        dirLight2.shadow.camera.left = 0;
        dirLight2.shadow.camera.near = 24;
        dirLight2.shadow.camera.far = 200;
        dirLight2.shadow.bias = -0.0005;

        this.scene.add(dirLight2);

        this.isDebug && EighthServiceDebug.ambLightDebug(this.scene, ambientLight);
        this.isDebug && EighthServiceDebug.initDirLight1(this.scene, dirLight1, 1);
        this.isDebug && EighthServiceDebug.initDirLight1(this.scene, dirLight2, 2);
    }

    initCamera = () => {
        super.initCamera(24.86);
    }

    initCameraControls = () => {
        super.initCameraControls();
        this.isDebug && EighthServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_7);
    }

    moveCameraToNextScene = async () => {
        return super.moveCameraToNextScene(Scenes.SCENE_9);
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
