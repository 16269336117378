import React, {PropsWithChildren} from "react";
import {motion} from "framer-motion";
import classNames from "classnames";

type SceneUILayoutWrapperProps = {
    className?: string;
    startDelay?: number;
}

export const SceneUILayoutWrapper: React.FC<PropsWithChildren<SceneUILayoutWrapperProps>> = (props: PropsWithChildren<SceneUILayoutWrapperProps>) => {
    const {children, className, startDelay = 0.5} = props;
    const resultClassName = classNames("scene-ui", className)

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1, transition: {delay: startDelay}}}
            exit={{opacity: 0}}
            className={resultClassName}>
            {children}
        </motion.div>
    );
}