import React, {useContext} from "react";
import {AnimatePresence} from "framer-motion";
import {observer} from "mobx-react";
import {AnimatedBlockWrapper} from "../../AnimatedBlockWrapper";
import {HomeButton} from "./HomeButton";
import {SoundButton} from "./SoundButton";
import {AppContext, GlobalAppContext} from "../../../app-context/AppContext";
import {JoinByteButton} from "./JoinByteButton";
import {openJoinByteLink} from "../../../utils/UIUtils";
import "./MainControlButtons.less";

type ControlButtonsProps = {
    soundButtonVisible?: boolean;
    homeButtonVisible?: boolean;
    joinByteButtonVisible?: boolean;

    onHomeButtonClick?: () => void;
}

const MainControlButtons: React.FC<ControlButtonsProps> = (props: ControlButtonsProps) => {
    const context = useContext<AppContext>(GlobalAppContext);
    const {homeButtonVisible, onHomeButtonClick, soundButtonVisible, joinByteButtonVisible} = props;

    const onSoundButtonClick = () => {
        if (context.useSound) {
            context.currentSceneService.turnOffVolume(0.5);
            context.currentSceneService.useSound = false;
        } else {
            context.currentSceneService.turnOnVolume(0.5);
            context.currentSceneService.useSound = true;
        }

        context.useSound = !context.useSound;
    }

    return (
        <div className="main-control-buttons">
            <HomeControlButton visible={homeButtonVisible} onClick={onHomeButtonClick}/>
            <SoundControlButton visible={soundButtonVisible} onClick={onSoundButtonClick} on={context.useSound}/>
            <JoinByteControlButton visible={joinByteButtonVisible} onClick={openJoinByteLink}/>
        </div>
    );
}

export default observer(MainControlButtons);

type SoundControlButtonProps = {
    onClick?: () => void;
    visible?: boolean;
    on?: boolean;
}

export const SoundControlButton: React.FC<SoundControlButtonProps> = (props: SoundControlButtonProps) => {
    const {onClick, visible, on} = props;

    return (
        <AnimatePresence>
            {(visible == undefined || visible) &&
            <AnimatedBlockWrapper className="sound-button-control">
                <SoundButton onClick={onClick} on={on}/>
            </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}

type HomeControlButtonProps = {
    onClick?: () => void;
    visible?: boolean;
}

export const HomeControlButton: React.FC<HomeControlButtonProps> = (props: HomeControlButtonProps) => {
    const {onClick, visible} = props;

    return (
        <AnimatePresence>
            {(visible == undefined || visible) &&
            <AnimatedBlockWrapper className="home-button-control">
                <HomeButton onClick={onClick}/>
            </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}

type JoinByteControlButtonProps = {
    onClick?: () => void;
    visible?: boolean;
}

export const JoinByteControlButton: React.FC<JoinByteControlButtonProps> = (props: JoinByteControlButtonProps) => {
    const {onClick, visible} = props;

    return (
        <AnimatePresence>
            {(visible == undefined || visible) &&
            <AnimatedBlockWrapper className="join-byte-button-control">
                <JoinByteButton onClick={onClick}/>
            </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}
