import React, {useContext, useEffect, useState} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {NinthSceneCameraPosition} from "../../service/scene-9/NinthSceneCameraPosition";
import {NinthSceneEvents} from "../../service/scene-9/NinthSceneEvents";
import classNames from "classnames";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {AnimatedBlockWrapper} from "../AnimatedBlockWrapper";
import {AnimatePresence} from "framer-motion";
import {NinthSceneService} from "../../service/scene-9/NinthSceneService";
import {openJoinByteLink, openLink} from "../../utils/UIUtils";
import {SocialButtons} from "../common/scial-buttons/SocialButtons";
import "./NinthSceneUI.less";

const NinthSceneUI: React.FC = () => {
    const {currentSceneService, cameraIsMoving} = useContext<AppContext>(GlobalAppContext);
    const [cameraPosition, setCameraPosition] = useState<NinthSceneCameraPosition>(NinthSceneCameraPosition.TOP);

    useEffect(() => {
        if (currentSceneService) {
            const removeConsumer = (currentSceneService as NinthSceneService).consumeOnEvent(
                NinthSceneEvents.CAMERA_POSITION_CHANGED,
                position => setCameraPosition(position)
            );

            return () => removeConsumer();
        }
    }, [currentSceneService]);

    const onDownClick = () => {
        (currentSceneService as NinthSceneService).moveCameraToSecondView();
    }

    const onUpClick = () => {
        (currentSceneService as NinthSceneService).moveCameraToFirstView();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as NinthSceneService).moveCameraToPreviousScene();
    }

    const positionTop = !cameraIsMoving && cameraPosition === NinthSceneCameraPosition.TOP;
    const positionBottom = !cameraIsMoving && cameraPosition === NinthSceneCameraPosition.BOTTOM;
    const resultClass = classNames("ninth-scene-ui", {"last-view": cameraPosition === NinthSceneCameraPosition.BOTTOM})

    return (
        <SceneUILayoutWrapper className={resultClass}>
            <ScenesControlButtons
                toNextSceneClick={positionTop ? onDownClick : openJoinByteLink}
                toNextSceneText={positionBottom ? "Join Byte" : undefined}
                toNexSceneButtonType={positionBottom ? "main" : "default"}
                toNexSceneButtonArrowType={positionBottom ? null : "up"}
                toPreviousSceneClick={positionTop ? toPreviousSceneClick : onUpClick}
                visible={!cameraIsMoving}/>

            <InvestorsPanel visible={positionTop}/>
            <SecondViewLabels visible={positionBottom}/>
            <SocialButtons visible={positionBottom}/>

            <MainControlButtons soundButtonVisible={!cameraIsMoving}
                                homeButtonVisible={!cameraIsMoving}
                                joinByteButtonVisible={!cameraIsMoving}
                                onHomeButtonClick={() => (currentSceneService as NinthSceneService).moveCameraToFirstScene()}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(NinthSceneUI);

type LabelsProps = {
    visible: boolean;
}

const InvestorsPanel: React.FC<LabelsProps> = (props: LabelsProps) => {
    const {visible} = props;

    return (
        <AnimatePresence>
            {visible &&
            <AnimatedBlockWrapper>
                <div className="investors-panel">
                    <div className="background-gradient"/>
                    <div className="header">Backed by</div>

                    <div className="investors-table">
                        <div className="investor">
                            <span className="investor-image red-alpine"/>
                        </div>
                        <div className="investor">
                            <span className="investor-image possible"/>
                        </div>
                        <div className="investor">
                            <span className="investor-image d4-ventures"/>
                        </div>
                        <div className="investor">
                            <span className="investor-image l1d"/>
                        </div>
                    </div>
                </div>
            </AnimatedBlockWrapper>}
        </AnimatePresence>
    );
}

const SecondViewLabels: React.FC<LabelsProps> = (props: LabelsProps) => {
    const {visible} = props;

    const onPrivacyClick = () => {
        openLink("https://byte-trading.com/privacy-policy.html");
    }

    const onDawnClick = () => {
        openLink("https://damnn.tv/");
    }

    return(
        <AnimatePresence>
            {visible &&
            <>
                <AnimatedBlockWrapper>
                    <span className="dawnn-logo" onClick={onDawnClick}/>
                </AnimatedBlockWrapper>
                <AnimatedBlockWrapper>
                    <span onClick={onPrivacyClick} className="privacy">Privacy policy</span>
                </AnimatedBlockWrapper>
            </>}
        </AnimatePresence>
    )
}
