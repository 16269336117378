import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {LinearFilter, MeshBasicMaterial, RepeatWrapping, Texture} from "three";

export class PersScene {

    name: PersName;

    gltf: GLTF;

    screenMaterial: MeshBasicMaterial;

    constructor(name: PersName, gltf: GLTF, texture: Texture) {
        this.name = name;
        this.gltf = gltf;
        this.initScreenMaterial(texture);
    }

    initScreenMaterial = (texture: Texture) => {
        texture.rotation = Math.PI / 2;
        texture.flipY = false;
        texture.generateMipmaps = false;
        texture.minFilter = LinearFilter;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.repeat.set(4, 4);
        texture.offset.set(0, 0.5);

        this.screenMaterial = new MeshBasicMaterial({map: texture});
    }
}

export enum PersName {
    NFT0009 = "NFT0009",
    NFT0111 = "NFT0111",
    NFT0146 = "NFT0146",
    NFT0170 = "NFT0170",
    NFT0197 = "NFT0197",
    NFT0214 = "NFT0214",
    // NFT0236 = "NFT0236", deleted
    NFT0320 = "NFT0320",
    NFT0363 = "NFT0363",
    // NFT0026 = "NFT0026", deleted
    NFT0428 = "NFT0428"
}
