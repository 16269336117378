import {v4 as uuid} from 'uuid';

/**
 * Service side events enum.
 */
export enum ScenesEvents {
    LOADING_BEGUN = "LOADING_BEGUN",
    LOADING_IN_PROGRESS = "LOADING_IN_PROGRESS",
    LOADING_COMPLETED = "LOADING_COMPLETED",
    WAIT_USER_ACTION = "WAIT_USER_ACTION",
    CANVAS_RESIZED = "CANVAS_RESIZED",
    HTML_ELEMENTS_COORDINATES_UPDATED = "HTML_ELEMENTS_COORDINATES_UPDATED",
    CAMERA_MOVING_BEGUN = "CAMERA_MOVING_BEGUN",
    CAMERA_MOVING_PROGRESS = "CAMERA_MOVING_PROGRESS",
    CAMERA_MOVING_COMPLETED = "CAMERA_MOVING_COMPLETED",
    SCENE_CHANGED = "SCENE_CHANGED",
    SCENE_CHANGING_PROCESS = "SCENE_CHANGING_PROCESS",
}

/**
 * Подписчик на событие сцены. По сути содержит ID для возможности удаления и коллбэк для вызова при событии.
 */
export class SceneEventConsumer {

    id: string;

    callback: SceneEventConsumerCallback;

    constructor(callback: SceneEventConsumerCallback) {
        this.id = uuid();
        this.callback = callback;
    }
}

/**
 * Consumer callback type.
 */
export type SceneEventConsumerCallback = (value?: any) => void;