import React, {useContext} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {InfoPanel, InfoPanelHeader, InfoPanelText} from "../InfoPanel";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import {SecondSceneService} from "../../service/scene-2/SecondSceneService";
import "./SecondSceneUI.less";

const SecondSceneUI: React.FC = () => {
    const {currentSceneService} = useContext<AppContext>(GlobalAppContext);

    const toNextSceneClick = () => {
        (currentSceneService as SecondSceneService).moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as SecondSceneService).moveCameraToPreviousScene();
    }

    return (
        <SceneUILayoutWrapper className="second-scene-ui">
            <InfoPanel>
                <InfoPanelText>
                    We help create liquid and efficient markets on centralised and decentralised exchanges.
                </InfoPanelText>
                <InfoPanelHeader>
                    Byte builds technology for crypto derivatives markets
                </InfoPanelHeader>
            </InfoPanel>
            <MainControlButtons onHomeButtonClick={() => (currentSceneService as SecondSceneService).moveCameraToFirstScene()}/>
            <ScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}
                toPreviousSceneText="To the street"
                toPreviousSceneButtonArrowType="left"/>
        </SceneUILayoutWrapper>
    );
}

export default observer(SecondSceneUI);
