import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Scenes} from "service/Scenes";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {AnimatePresence} from "framer-motion";
import SixthSceneCanvas from "./SixthSceneCanvas";
import SixthSceneUI from "./SixthSceneUI";

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const SixthScene: React.FC = () => {
    const {currentScene, dataLoading, sceneChangingProcess} = useContext<AppContext>(GlobalAppContext);
    const visible = currentScene === Scenes.SCENE_6 && !dataLoading;

    return (
        <>
            <SixthSceneCanvas visible={visible}/>
            <AnimatePresence>{visible && !sceneChangingProcess && <SixthSceneUI/>}</AnimatePresence>
        </>
    );
}

export default observer(SixthScene);