import React, {PropsWithChildren} from "react";
import classNames from "classnames";
import "./CircleButton.less";

type CircleButtonProps = {
    className?: string;
    onClick?: () => void;
}

export const CircleButton: React.FC<PropsWithChildren<CircleButtonProps>> = (props: PropsWithChildren<CircleButtonProps>) => {
    const {children, className, onClick} = props;

    const resultClassName = classNames("circle-button", className);

    return (
        <div className={resultClassName} onClick={onClick}>
            <span className="circle"/>
            <span className="content">{children}</span>
        </div>
    );
}
