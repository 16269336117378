import {ScenesEvents, SceneEventConsumer, SceneEventConsumerCallback,} from "service/SceneEvents";
import {Scenes} from "service/Scenes";

export class CommonEventsService {

    eventsConsumers: Map<ScenesEvents, SceneEventConsumer[]> = new Map<ScenesEvents, SceneEventConsumer[]>();

    /**
     * Находит список подписчиков на событие и вызывает их.
     */
    fireEvent = (event: ScenesEvents, data?: any) => {
        const consumers = this.eventsConsumers.get(event);
        if (consumers) {
            consumers.forEach(cons => cons.callback(data))
        }
    }

    /**
     * Consumes callback on event. Returns callback for removing consumer from the consumers list.
     *
     * @param sceneEvent
     * @param callBack
     */
    consumeOnEvent = (sceneEvent: ScenesEvents, callBack: SceneEventConsumerCallback) => {
        const newConsumer = new SceneEventConsumer(callBack);
        let consumers = this.eventsConsumers.get(sceneEvent);

        if (!consumers) {
            consumers = [];
            this.eventsConsumers.set(sceneEvent, consumers);
        }

        consumers.push(newConsumer);
        this.eventsConsumers.set(sceneEvent, consumers)

        return () => {
            consumers = consumers.filter(consumer => consumer.id !== newConsumer.id);
            this.eventsConsumers.set(sceneEvent, consumers);
        }
    }

    fireCameraMovingBegunEvent = () => {
        this.fireEvent(ScenesEvents.CAMERA_MOVING_BEGUN);
    }

    fireCameraMovingCompletedEvent = () => {
        this.fireEvent(ScenesEvents.CAMERA_MOVING_COMPLETED);
    }

    fireLoadingBegunEvent = () => {
        this.fireEvent(ScenesEvents.LOADING_BEGUN);
    }

    fireLoadingCompletedEvent = () => {
        this.fireEvent(ScenesEvents.LOADING_COMPLETED);
    }

    fireWaitUserActionEvent = () => {
        this.fireEvent(ScenesEvents.WAIT_USER_ACTION);
    }

    fireCanvasResizedEvent = () => {
        this.fireEvent(ScenesEvents.CANVAS_RESIZED);
    }

    fireHTMLElementsCoordinatesUpdatedEvent = () => {
        this.fireEvent(ScenesEvents.HTML_ELEMENTS_COORDINATES_UPDATED);
    }

    fireSceneChangingProcessEvent = (nextScene: Scenes) => {
        this.fireEvent(ScenesEvents.SCENE_CHANGING_PROCESS, nextScene);
    }

    fireSceneChangedEvent = (nextScene: Scenes) => {
        this.fireEvent(ScenesEvents.SCENE_CHANGED, nextScene);
    }
}