import React, {useCallback, useContext} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {motion, Variants} from "framer-motion";

type LoaderSceneCanvasProps = {
    visible: boolean;
}

/**
 * Сцена для рендеринга сцены снаружи здания.
 */
const LoaderSceneCanvas: React.FC<LoaderSceneCanvasProps> = (props: LoaderSceneCanvasProps) => {
    const {createLoaderSceneService, firstLoading} = useContext<AppContext>(GlobalAppContext);

    const canvasRef = useCallback((canvas: HTMLCanvasElement) => {
        if (canvas) {
            createLoaderSceneService(canvas);
        }
    }, []);

    const sceneVariants: Variants = {
        show: {
            display: "block",
            opacity: 1,
            transition: {
                delay: firstLoading ? 0 : 0.2
            }
        },
        hide: {
            opacity: 0,
            transitionEnd: {
                display: "none"
            }
        }
    }

    const className = classNames("scene-canvas", "loader-scene-canvas")

    return (
        <motion.canvas
            className={className}
            ref={canvasRef}
            variants={sceneVariants}
            initial={"hide"}
            animate={props.visible ? "show" : "hide"}
            transition={{duration: 0.2}}/>
    );
}

export default observer(LoaderSceneCanvas);