import {
    AmbientLight,
    CameraHelper,
    DirectionalLight,
    DirectionalLightHelper, Group,
    PerspectiveCamera, PointLight,
    PointLightHelper,
    Scene, SpotLight, SpotLightHelper
} from "three";
import {GUI} from "dat.gui";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

// const gui_3 = new GUI({name: "SEVENTH SCENE DEBUG"})

/**
 * Класс для добавления инструментов дебага сцены. Создан для чистоты основной логики.
 */
export class SeventhServiceDebug {

    static initLightsDebug = (
        scene: Scene,
        dirLight: DirectionalLight,
        ambientLight: AmbientLight,
        pointLight: PointLight,
        spotLight: SpotLight
    ) => {
        /*const dirLightHelper = new DirectionalLightHelper(dirLight, 10);
        scene.add(dirLightHelper);
        const helper = new CameraHelper(dirLight.shadow.camera);
        scene.add(helper);

        const data = {
            color: dirLight.color.getHex(),
            mapsEnabled: true,
        }

        const ambientLightFolder = gui_3.addFolder('Ambient light');
        ambientLightFolder.add(ambientLight, 'intensity').min(0).max(5).step(0.001);

        const directionalLightFolder = gui_3.addFolder('Directional light')
        directionalLightFolder.addColor(data, 'color').onChange(() => {
            dirLight.color.setHex(Number(data.color.toString().replace('#', '0x')))
        })
        directionalLightFolder.add(dirLight, 'intensity', 0, 5, 0.01)

        directionalLightFolder
            .add(dirLight.shadow.camera, 'left', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'right', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'top', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'bottom', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'near', 0.1, 200)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'far', 0.1, 200)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'bias', -0.001, 0.001).step(0.00001)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'radius', 0, 10).step(0.001)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder.add(dirLight.position, 'x', -50, 100, 0.01)
        directionalLightFolder.add(dirLight.position, 'y', -50, 100, 0.01)
        directionalLightFolder.add(dirLight.position, 'z', -50, 100, 0.01)


        const pointLightHelper = new PointLightHelper(pointLight, 1);
        scene.add(pointLightHelper)
        const plFolder = gui_3.addFolder('Point light');
        plFolder.add(pointLight, 'intensity').min(0).max(2).step(0.001);
        plFolder.add(pointLight.position, 'x', -50, 50, 0.01)
        plFolder.add(pointLight.position, 'y', -50, 50, 0.01)
        plFolder.add(pointLight.position, 'z', -50, 50, 0.01)
        plFolder
            .add(pointLight.shadow, 'bias', -0.001, 0.001).step(0.00001)
            .onChange(() => pointLight.shadow.camera.updateProjectionMatrix())
        plFolder
            .add(pointLight.shadow, 'radius', 0, 10).step(0.001)
            .onChange(() => pointLight.shadow.camera.updateProjectionMatrix())

        const spotLightHelper = new SpotLightHelper(spotLight);
        scene.add(spotLightHelper);
        const spotFolder = gui_3.addFolder('Spot light');
        spotFolder.add(spotLight, 'intensity').min(0).max(2).step(0.001);
        spotFolder.add(spotLight.position, 'x', -50, 50, 0.01);
        spotFolder.add(spotLight.position, 'y', -50, 50, 0.01);
        spotFolder.add(spotLight.position, 'z', -50, 50, 0.01);
        spotFolder.add(spotLight.target.position, 'x', -50, 50, 0.01);
        spotFolder.add(spotLight.target.position, 'y', -50, 50, 0.01);
        spotFolder.add(spotLight.target.position, 'z', -50, 50, 0.01);
        spotFolder.add(spotLight, 'angle', 0, 1, 0.01);
        spotFolder
            .add(spotLight.shadow.camera, 'near', 0.1, 50)
            .onChange(() => spotLight.shadow.camera.updateProjectionMatrix())
        spotFolder
            .add(spotLight.shadow.camera, 'far', 0.1, 50)
            .onChange(() => spotLight.shadow.camera.updateProjectionMatrix())
        spotFolder
            .add(spotLight.shadow, 'bias', -0.001, 0.001).step(0.00001)
            .onChange(() => spotLight.shadow.camera.updateProjectionMatrix());
        spotFolder
            .add(spotLight.shadow, 'radius', 0, 10).step(0.001)
            .onChange(() => spotLight.shadow.camera.updateProjectionMatrix());
        spotFolder.add(spotLight, 'penumbra', 0, 1, 0.01);
        spotFolder.add(spotLight, 'decay', 0, 1, 0.01);
        spotFolder.add(spotLight.shadow, 'focus', 0, 1, 0.01);*/
    }

    static initCameraDebug = (scene: Scene, camera: PerspectiveCamera, controls: OrbitControls) => {
        /*const cameraFolder = gui_3.addFolder('Scene 3 Camera');
        cameraFolder.add(camera.position, "x", -100, 100, 0.01);
        cameraFolder.add(camera.position, "y", -100, 100, 0.01);
        cameraFolder.add(camera.position, "z", -100, 100, 0.01);
        cameraFolder
            .add(camera, "fov", 0, 45, 0.01)
            .onChange(() => camera.updateProjectionMatrix());
        cameraFolder.add(controls.target, "x", -100, 100, 0.01);
        cameraFolder.add(controls.target, "y", -100, 100, 0.01);
        cameraFolder.add(controls.target, "z", -100, 100, 0.01);*/
    }

    static checkPosition = (pers: Group, n: number) => {
        /*const cameraFolder = gui_3.addFolder('Pers ' + n);
        cameraFolder.add(pers.position, "x", -10, 10, 0.01);
        cameraFolder.add(pers.position, "y", -10, 10, 0.01);
        cameraFolder.add(pers.position, "z", -10, 10, 0.01);*/
    }
}