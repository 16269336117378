import {makeAutoObservable} from "mobx";
import React from "react";
import {CommonEventsService} from "service/CommonEventsService";
import {ScenesEvents} from "service/SceneEvents";
import {LoaderSceneService} from "service/loader-scene/LoaderSceneService";
import {Scenes} from "service/Scenes";
import {SceneService} from "../service/SceneService";

/**
 * Global app context.
 */
export class AppContext {

    currentScene: Scenes = Scenes.CITY_SCENE;

    currentSceneService: SceneService;

    /**
     * Сервис сцены с загрузчиком.
     */
    loaderSceneService: LoaderSceneService = null;

    /**
     * Сервис общих событий для любых сцен.
     */
    commonEventsService: CommonEventsService = new CommonEventsService();

    /**
     * Признак того, что камера находится в движении
     */
    cameraIsMoving: boolean = false;

    /**
     * Признак того, что выполняется загрузка данных.
     */
    dataLoading: boolean = true;

    /**
     * Признак того, что выполняется загрузка данных.
     */
    sceneChangingProcess: boolean = false;

    firstLoading: boolean = true;

    userContinueAction: boolean = false;

    waitUserContinueAction: boolean = false;

    useSound: boolean = true;

    showCitySceneBanner: boolean = true;

    constructor() {
        makeAutoObservable(this);
        this.consumeOnCommonEvents();
    }

    setCurrentSceneService = (sceneService: SceneService) => {
        this.currentSceneService = sceneService;
    }

    /**
     * Создаёт экземпляр сервиса общих событий и подписывается на некоторые из них, для удобства использованиея из UI
     * флагов.
     */
    consumeOnCommonEvents = () => {
        this.commonEventsService = new CommonEventsService();
        this.commonEventsService.consumeOnEvent(ScenesEvents.CAMERA_MOVING_BEGUN, () => this.cameraIsMoving = true);
        this.commonEventsService.consumeOnEvent(ScenesEvents.CAMERA_MOVING_COMPLETED, () => this.cameraIsMoving = false);
        this.commonEventsService.consumeOnEvent(ScenesEvents.WAIT_USER_ACTION, () => this.waitUserContinueAction = true);

        this.commonEventsService.consumeOnEvent(ScenesEvents.LOADING_BEGUN, () => {
            this.dataLoading = true;
            this.loaderSceneService?.startRendering();
        });

        this.commonEventsService.consumeOnEvent(ScenesEvents.LOADING_COMPLETED, () => {
            if (this.firstLoading) {
                this.firstLoading = false;
            }

            this.dataLoading = false;
            this.loaderSceneService?.stopRendering();
        });

        this.commonEventsService.consumeOnEvent(ScenesEvents.SCENE_CHANGING_PROCESS, this.sceneChangingProcessConsumer);
        this.commonEventsService.consumeOnEvent(ScenesEvents.SCENE_CHANGED, this.sceneChangedEventConsumer);
    }

    sceneChangingProcessConsumer = () => {
        this.sceneChangingProcess = true;
    }

    sceneChangedEventConsumer = (nextScene: Scenes) => {
        this.currentScene = nextScene;
        this.sceneChangingProcess = false;
        // console.debug("SCENE CHANGED", nextScene)
    }

    createLoaderSceneService = (canvas: HTMLCanvasElement) => {
        if (!this.loaderSceneService) {
            this.loaderSceneService = new LoaderSceneService(canvas, this.commonEventsService, this.useSound);
        }
    }
}

export const GlobalAppContext = React.createContext(null);