import React, {useContext} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {InfoPanel, InfoPanelHeader} from "../InfoPanel";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import {ThirdSceneService} from "../../service/scene-3/ThirdSceneService";
import "./ThirdSceneUI.less";

const ThirdSceneUI: React.FC = () => {
    const {currentSceneService} = useContext<AppContext>(GlobalAppContext);

    const toNextSceneClick = () => {
        (currentSceneService as ThirdSceneService).moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as ThirdSceneService).moveCameraToPreviousScene();
    }

    return (
        <SceneUILayoutWrapper className="third-scene-ui">
            <InfoPanel>
                <InfoPanelHeader>
                    We are building a derivatives native technology stack for better pricing and risk management of positions
                </InfoPanelHeader>
            </InfoPanel>
            <MainControlButtons onHomeButtonClick={() => (currentSceneService as ThirdSceneService).moveCameraToFirstScene()}/>
            <ScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(ThirdSceneUI);
