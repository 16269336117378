import {
    AmbientLight,
    CameraHelper,
    DirectionalLight,
    DirectionalLightHelper,
    PerspectiveCamera,
    PointLight, PointLightHelper, RectAreaLight,
    Scene
} from "three";
import {GUI} from "dat.gui";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {RectAreaLightHelper} from "three/examples/jsm/helpers/RectAreaLightHelper";

// const gui = new GUI({name: "SECOND SCENE DEBUG"})

/**
 * Класс для добавления инструментов дебага сцены. Создан для чистоты основной логики.
 */
export class SecondSceneServiceDebug {

    static initLightsDebug = (scene: Scene, dirLight: DirectionalLight, ambientLight: AmbientLight, pointLight: RectAreaLight) => {
        /*gui.add(ambientLight, 'intensity').min(0).max(2).step(0.001);

        const dirLightHelper = new DirectionalLightHelper(dirLight, 10);
        scene.add(dirLightHelper);
        const helper = new CameraHelper(dirLight.shadow.camera);
        scene.add(helper);

        const data = {
            color: dirLight.color.getHex(),
            mapsEnabled: true,
        }

        const lightFolder = gui.addFolder('Lights')
        lightFolder.addColor(data, 'color').onChange(() => {
            dirLight.color.setHex(Number(data.color.toString().replace('#', '0x')))
        })
        lightFolder.add(dirLight, 'intensity', 0, 3, 0.01)

        const directionalLightFolder = gui.addFolder('DirectionalLight')
        directionalLightFolder
            .add(dirLight.shadow.camera, 'left', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'right', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'top', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'bottom', -100, 100, 0.1)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'near', 0.1, 200)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow.camera, 'far', 0.1, 200)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'bias', -0.001, 0.001).step(0.00001)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder
            .add(dirLight.shadow, 'radius', -100, 100).step(0.01)
            .onChange(() => dirLight.shadow.camera.updateProjectionMatrix())
        directionalLightFolder.add(dirLight.position, 'x', -360, 360, 0.01)
        directionalLightFolder.add(dirLight.position, 'y', -360, 360, 0.01)
        directionalLightFolder.add(dirLight.position, 'z', -360, 360, 0.01)


        const pointLightFolder = gui.addFolder('PointLight')
        const pointLightHelper = new RectAreaLightHelper(pointLight, 1);
        scene.add(pointLightHelper);
        pointLightFolder.add(pointLight, 'intensity', 0, 3, 0.01)

        pointLightFolder.add(pointLight.position, 'x', -30, 10, 0.01)
        pointLightFolder.add(pointLight.position, 'y', -30, 10, 0.01)
        pointLightFolder.add(pointLight.position, 'z', -30, 10, 0.01)

        pointLightFolder.add(pointLight.rotation, 'x', -Math.PI, Math.PI, 0.01)
        pointLightFolder.add(pointLight.rotation, 'y', -Math.PI, Math.PI, 0.01)
        pointLightFolder.add(pointLight.rotation, 'z', -Math.PI, Math.PI, 0.01)

        pointLightFolder.add(pointLight, 'width', -10, 10, 0.01)
        pointLightFolder.add(pointLight, 'height', -10, 10, 0.01)*/
    }

    static initCameraDebug = (scene: Scene, camera: PerspectiveCamera, controls: OrbitControls) => {
        /*const cameraFolder = gui.addFolder('Camera');
        cameraFolder.add(camera.position, "x", -100, 100, 0.01);
        cameraFolder.add(camera.position, "y", -100, 100, 0.01);
        cameraFolder.add(camera.position, "z", -100, 100, 0.01);
        cameraFolder.add(controls.target, "x", -100, 100, 0.01);
        cameraFolder.add(controls.target, "y", -100, 100, 0.01);
        cameraFolder.add(controls.target, "z", -100, 100, 0.01);*/
    }
}