import {gsap} from "gsap";
import {PerspectiveCamera, Vector3} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

/**
 * Выполняет плавное изменение значения.
 */
export const interpolateValue = (
    from: number,
    to: number,
    duration: number = 1,
    onUpdate?: (value?: number) => void,
    onComplete?: (value?: number) => void,
) => {

    const container = {value: from};
    return gsap.to(container, {
        value: to,
        duration: duration,
        ease: 'power2.inOut',
        onUpdate: () => onUpdate?.(container.value),
        onComplete: () => onComplete?.(container.value)
    });
};

/**
 * Выполняет плавное изменение вектора {@link Vector3}.
 */
export const interpolateVector = (
    fromVector: Vector3,
    toVector: Vector3,
    duration: number = 1,
    onUpdateCB?: (value: Vector3) => void,
    onCompleteCB?: (value: Vector3) => void
) => {
    return gsap.to(fromVector, {
        ...toVector,
        duration: duration,
        ease: 'power2.inOut',
        onUpdate: () => onUpdateCB?.(fromVector),
        onComplete: () => onCompleteCB?.(fromVector)
    });
};

/**
 * Выполняет плавное изменение положения камеры.
 */
export const interpolateCameraControls = (
    camera: PerspectiveCamera,
    controls: OrbitControls,
    newCameraPosition: Vector3,
    newTargetPosition: Vector3,
    newTargetZoom: number,
    duration: number = 1
) => {
    return Promise.allSettled([
        new Promise((resolve) => {
            interpolateVector(
                camera.position,
                newCameraPosition,
                duration,
                undefined,
                resolve
            );
        }),
        new Promise((resolve) => {
            interpolateVector(
                controls.target,
                newTargetPosition,
                duration,
                undefined,
                resolve
            );
        }),
        new Promise((resolve) => {
            interpolateValue(
                camera.zoom,
                newTargetZoom,
                duration,
                (value) => camera.zoom = value,
                resolve
            );
        })
    ]);
}
