import React, {useContext} from "react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import {observer} from "mobx-react";
import {SceneUILayoutWrapper} from "../SceneUILayoutWrapper";
import {InfoPanel, InfoPanelHeader, InfoPanelText} from "../InfoPanel";
import MainControlButtons from "../common/control-buttons/MainControlButtons";
import {ScenesControlButtons} from "../common/scenes-control-buttons/ScenesControlButtons";
import {FifthSceneService} from "../../service/scene-5/FifthSceneService";
import "./FifthSceneUI.less";

const FifthSceneUI: React.FC = () => {
    const {currentSceneService} = useContext<AppContext>(GlobalAppContext);

    const toNextSceneClick = () => {
        (currentSceneService as FifthSceneService).moveCameraToNextScene();
    }

    const toPreviousSceneClick = () => {
        (currentSceneService as FifthSceneService).moveCameraToPreviousScene();
    }

    return (
        <SceneUILayoutWrapper className="fifth-scene-ui">
            <InfoPanel>
                <InfoPanelHeader>We are not magicians. We don’t try to predict the future</InfoPanelHeader>
                <InfoPanelText>
                    But we believe the future of the internet is in Web3, and we are building an infrastructure to support this.
                </InfoPanelText>
            </InfoPanel>
            <MainControlButtons onHomeButtonClick={() => (currentSceneService as FifthSceneService).moveCameraToFirstScene()}/>
            <ScenesControlButtons
                toNextSceneClick={toNextSceneClick}
                toPreviousSceneClick={toPreviousSceneClick}/>
        </SceneUILayoutWrapper>
    );
}

export default observer(FifthSceneUI);
