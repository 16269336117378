import {AmbientLight, DirectionalLight, Mesh, Vector3} from "three";
import {CommonEventsService} from "service/CommonEventsService";
import {SixthServiceDebug} from "service/scene-6/SixthServiceDebug";
import {Scenes} from "service/Scenes";
import {SceneService} from "service/SceneService";
import {Constants} from "../../utils/Constants";
import {SkyboxSingleton} from "../SkyboxSingleton";

/**
 * Основной класс для обработки 3D сцены снаружи здания.
 */
export class SixthSceneService extends SceneService {

    constructor(canvas: HTMLCanvasElement, commonEventsService: CommonEventsService, useSound: boolean, userContinueAction?: boolean) {
        super(commonEventsService, canvas, Scenes.SCENE_6, useSound, userContinueAction);
        this.initialCameraPosition = new Vector3(7.17, 7.97, 7.85);
        this.initialCameraTargetPosition = new Vector3(-2.45, 3.37, 0.91);
        this.isDebug = false;
        this.init();
    }

    init = () => {
        super.init();
        this.initLights();
        this.loadAudio(`${Constants.SOUNDS_BASE_PATH}/scene_6.mp3`);
        this.loadSceneModel(`${Constants.MODELS_BASE_PATH}/scene_6.glb`);
    }

    initLights = () => {
        const ambientLight = new AmbientLight(0xffffff, 0.56);

        this.scene.add(ambientLight);

        const directionalLight = new DirectionalLight(0xff895f, 2);
        directionalLight.position.set(-15.37, 24, 50);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(1024, 1024);
        directionalLight.shadow.camera.top = 10;
        directionalLight.shadow.camera.right = 5;
        directionalLight.shadow.camera.bottom = -4;
        directionalLight.shadow.camera.left = -11.5;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 200;
        directionalLight.shadow.bias = -0.001;

        this.scene.add(directionalLight);

        const directionalLight2 = new DirectionalLight(0xffffff, 0.5);
        directionalLight2.position.set(6.31, 23.45, -44.38);
        directionalLight2.castShadow = false;

        this.scene.add(directionalLight2);

        // this.isDebug && SixthServiceDebug.initDirLight(this.scene, directionalLight, 1);
        // this.isDebug && SixthServiceDebug.initDirLight(this.scene, directionalLight2, 2);
    }

    initCamera = () => {
        super.initCamera(32.46);
    }

    initCameraControls = () => {
        super.initCameraControls();
        // this.isDebug && SixthServiceDebug.initCameraDebug(this.scene, this.camera, this.cameraControls);
    }

    initSkybox() {
        this.skybox = SkyboxSingleton.getSkybox(this.loadManager, new Vector3(25, 25, 25));
        this.scene.add(this.skybox);
        this.skybox.position.set(-5.16, -1.19, -2.08);
        this.skybox.rotation.set(0, 1.85, 0);
        this.isDebug && SixthServiceDebug.initSkyboxDebug(this.skybox);
    }

    moveCameraToPreviousScene = async () => {
        return super.moveCameraToPreviousScene(Scenes.SCENE_5);
    }

    moveCameraToNextScene = async () => {
        return super.moveCameraToNextScene(Scenes.SCENE_7);
    }

    moveCameraToFirstScene() {
        return super.moveCameraToPreviousScene(Scenes.CITY_SCENE);
    }

    processMeshShadowing(mesh: Mesh<any, any>) {
        mesh.receiveShadow = true;
        mesh.castShadow = true;
    }

    render = () => {
        this.requestAnimationFrameId = window.requestAnimationFrame(this.render);
        this.cameraControls?.update();
        this.animationMixer?.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
    }
}
