import React, {PropsWithChildren} from "react";
import {AnimatePresence} from "framer-motion";
import {Button, ButtonArrowType, ButtonType} from "../button/Button";
import {AnimatedBlockWrapper} from "../../AnimatedBlockWrapper";
import "./ScenesControlButtons.less";
import {ConditionShow} from "../../ConditionShow";
import classNames from "classnames";

type ScenesControlButtonsProps = {
    toPreviousSceneClick?: () => void;
    toPreviousSceneText?: string;
    toPreviousSceneButtonType?: ButtonType;
    toPreviousSceneButtonArrowType?: ButtonArrowType;
    toNextSceneClick?: () => void;
    toNextSceneText?: string;
    toNexSceneButtonType?: ButtonType;
    toNexSceneButtonArrowType?: ButtonArrowType;
    visible?: boolean;
    className?: string;
}

export const ScenesControlButtons: React.FC<PropsWithChildren<ScenesControlButtonsProps>> = (props: PropsWithChildren<ScenesControlButtonsProps>) => {
    const {
        toNextSceneClick,
        toPreviousSceneClick,
        toNextSceneText = "Next level",
        toPreviousSceneText = "Previous level",
        visible = true,
        toNexSceneButtonType,
        toPreviousSceneButtonType,
        toNexSceneButtonArrowType = "up",
        toPreviousSceneButtonArrowType = "down",
        children,
        className
    } = props;

    const resultClassName = classNames("scenes-control-buttons", className);

    return (
        <AnimatePresence>
            {
                visible &&
                <div className={resultClassName}>
                    <AnimatedBlockWrapper>
                        <Button onClick={toPreviousSceneClick} type={toPreviousSceneButtonType} arrowType={toPreviousSceneButtonArrowType}>{toPreviousSceneText}</Button>
                    </AnimatedBlockWrapper>
                    <ConditionShow condition={!!children}>
                        {children}
                    </ConditionShow>
                    <AnimatedBlockWrapper>
                        <Button onClick={toNextSceneClick} type={toNexSceneButtonType} arrowType={toNexSceneButtonArrowType}>{toNextSceneText}</Button>
                    </AnimatedBlockWrapper>
                </div>
            }
        </AnimatePresence>
    )
}