import React, {Component} from "react";
import {configure} from "mobx"
import {observer} from "mobx-react";
import {AppContext, GlobalAppContext} from "app-context/AppContext";
import LoaderScene from "components/loader-scene/LoaderScene";
import SecondScene from "components/scene-2/SecondScene";
import ThirdScene from "components/scene-3/ThirdScene";
import FifthScene from "./components/scene-5/FifthScene";
import SixthScene from "./components/scene-6/SixthScene";
import SeventhScene from "./components/scene-7/SeventhScene";
import EighthScene from "./components/scene-8/EighthScene";
import NinthScene from "./components/scene-9/NinthScene";
import {Scenes} from "./service/Scenes";
import CityScene from "./components/scene-1/CityScene";
import {TurnPhone} from "./components/turn-phone/TurnPhone";
import "./styles/common.less";

configure({
    enforceActions: "never",
});

/**
 * Main App container.
 *
 * @returns {JSX.Element}
 * @constructor
 */
@observer
export class App extends Component {

    appContext: AppContext = new AppContext();

    render() {
        // console.debug("IS MOBILE:", OSUtils.isMobile())

        const {currentScene} = this.appContext;

        return (
            <div className="App">
                <GlobalAppContext.Provider value={this.appContext}>
                    {currentScene === Scenes.CITY_SCENE && <CityScene/>}
                    {currentScene === Scenes.SCENE_2 && <SecondScene/>}
                    {currentScene === Scenes.SCENE_3 && <ThirdScene/>}
                    {currentScene === Scenes.SCENE_5 && <FifthScene/>}
                    {currentScene === Scenes.SCENE_6 && <SixthScene/>}
                    {currentScene === Scenes.SCENE_7 && <SeventhScene/>}
                    {currentScene === Scenes.SCENE_8 && <EighthScene/>}
                    {currentScene === Scenes.SCENE_9 && <NinthScene/>}
                    <LoaderScene/>
                    <TurnPhone/>
                </GlobalAppContext.Provider>
            </div>
        );
    }
}
