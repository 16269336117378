// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("main_background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n}\nhtml {\n  background-color: black;\n}\nhtml,\nbody,\n#root {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\nbody {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  -ms-overflow-style: none;\n  /* IE and Edge */\n  scrollbar-width: none;\n  /* Firefox */\n}\nbody::-webkit-scrollbar {\n  display: none;\n}\nbody.loading-state {\n  overflow: hidden;\n}\n.scene-canvas {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n}\n.scene-canvas.hide {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/common.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAEA;EACE,uBAAA;AAAF;AAGA;;;EACE,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AACF;AAEA;EACE,yDAAA;EACA,sBAAA;EACA,4BAAA;EAEA,wBAAA;EADA,gBAAgB;EAEhB,qBAAA;EAAA,YAAY;AACd;AACE;EACE,aAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAIA;EACE,eAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;AAFF;AAIE;EACE,aAAA;AAFJ","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\nhtml {\n  background-color: black;\n}\n\nhtml, body, #root {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  background-image: url(\"main_background.jpg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  &.loading-state {\n    overflow: hidden;\n  }\n}\n\n.scene-canvas {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n\n  &.hide {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
